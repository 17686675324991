import { DELETE_WIDGET_ARRAY_DETAILS, GET_WIDGET_DETAILS, GET_WORKFLOW_NAME, SAVE_WIDGET_DETAILS } from "../Constant"

const initialState = {
    widgetList: [],
    widgetSaved: [],
    workflowName: []
}
const insightReducer = (state = initialState, action) => {
    switch (action.type) {
        case GET_WIDGET_DETAILS:
            return {
                ...state,
                widgetList: action.payload.message
            }
        case SAVE_WIDGET_DETAILS:
            return {
                ...state,
                widgetSaved: [...state.widgetSaved, action.payload]
            }
        case DELETE_WIDGET_ARRAY_DETAILS:
            return {
                ...state,
                widgetSaved: action.payload
            }
        case GET_WORKFLOW_NAME:
            return {
                ...state,
                workflowName: action.payload.message
            }
        default:
            return state;
    }
}
export default insightReducer;