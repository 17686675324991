export const INITIAL_JOB_DESTINATION_DATA = {
    "AWS-S3": {
        bucketName: '',
        fileObjKeyName: '',
        filePrefix: '',
    },
}
export const INITIAL_CONFIG_DATA = {
    "AWS-S3": {
        bucketName: '',
        fileObjKeyName: '',
        filePrefix: '',
    },
    "Google Static API": {
        center: '',
        zoom: '',
        size: '450x450',
        scale: '',
        format: 'png',
        maptype: 'roadmap',
    },
    "Google Place Detail API": {
        address: '',
        basic: true,
        contactInfo: false,
        review: false
    },
    "Google Cloud Storage": {
        projectId: "",
        bucketName: '',
        fileObjKeyName: '',
        filePrefix: '',
    }
}