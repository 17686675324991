import React, { useEffect, useState } from 'react';
import { LayoutContainer } from '..';
import { ContentWrapper } from './style';
import { BarChart, LineChart, KeplerMap, GooglePieChart, ScatterChart, TimeLineChart, GeoChart, MapBoxGL, BubbleChart, Table, InsightComponent } from '../../Components';
import { useDispatch, useSelector } from 'react-redux';
import { deleteWidgetArray, getWidgetDetails, getWorkflowNames, widgetArray } from '../../Actions/integrationAction';
import { Helmet } from 'react-helmet';

let exist = ""
let valid = true
const InsightContainer = () => {
    const [addChart, setAddChart] = useState(null);
    const [chart, setChart] = useState([])
    const [widget, setWidget] = useState([])
    const openChart = Boolean(addChart);
    const [draggableIdSelect, setDraggableIdSelect] = useState(null)
    const [anchorEl, setAnchorEl] = useState(null);
    const [widgetDetail, updateWidgetDetail] = useState(null)
    const [options, setOptions] = useState([]);
    const [inputValue, setInputValue] = React.useState('');
    const [autoComplete, setAutoComplete] = useState(false)

    const dispatch = useDispatch();

    const { widgetList, widgetSaved, workflowName } = useSelector((state) => state.insight)

    useEffect(() => {
        dispatch(getWidgetDetails())
        dispatch(getWorkflowNames());
    }, [])
    const handleClick = (event) => {
        setAddChart(event.currentTarget);
    };

    const handleClose = () => {
        setAddChart(null);
    };

    const lineChart = () => {
        setChart([...chart, { newChart: <LineChart /> }])
    }

    const barChart = () => {
        setChart([...chart, { newChart: <BarChart /> }])
    }

    const pieChart = () => {
        setChart([...chart, { newChart: <GooglePieChart /> }])
    }

    const scatterChart = () => {
        setChart([...chart, { newChart: <ScatterChart /> }])
    }

    const timeLineChart = () => {
        setChart([...chart, { newChart: <TimeLineChart /> }])
    }

    const geoChart = () => {
        setChart([...chart, { newChart: <GeoChart /> }])
    }

    const bubbleChart = () => {
        setChart([...chart, { newChart: <BubbleChart /> }])
    }
    const keplerChart = () => {
        setChart([...chart, { newChart: <KeplerMap /> }])
    }

    const TableData = () => {
        setChart([...chart, { newChart: <Table /> }])
    }

    const widgets = () => {
        let find = widgetList?.find((item) => item.name === widgetDetail?.name?.name)
        const temp = find?.detail
        const scriptTag = temp?.substring(
            temp?.indexOf('<script type="text/javascript">') + 37,
            temp?.lastIndexOf('</script>')
        )
        const divTag = temp?.substring(temp.indexOf("<div>"), temp.indexOf("</div>")) + '</div>' + '</div>';
        return (
            <>
                {widgetDetail?.name && (<>
                    <div
                        dangerouslySetInnerHTML={{
                            __html: divTag
                        }}
                    />
                    < Helmet >
                        <script type="text/javascript" >{`${scriptTag}`}</script>
                    </Helmet>
                </>
                )}
            </>
        )
    }

    const addWidget = () => {
        exist = widget?.find((data) => data?.id === widgetDetail?.name?.widgetDetailId);
        if (widgetDetail && !exist && widgetDetail?.name?.name !== "mapbox") {
            setWidget((widget) => [...widget, { id: widgetDetail?.name?.widgetDetailId, graph: () => widgets() }])
            dispatch(widgetArray({ id: widgetDetail?.name?.widgetDetailId, graph: widgets() }))
        }
        else if (widgetDetail && !exist && widgetDetail?.name?.name === "mapbox") {
            let data = widgetDetail?.name?.detail
            setWidget([...widget, { id: widgetDetail?.name?.widgetDetailId, graph: <MapBoxGL dataJson={data} /> }])
            dispatch(widgetArray({ id: widgetDetail?.name?.widgetDetailId, graph: <MapBoxGL dataJson={data} /> }))
        }
    }

    const onDragEnd = (result) => {
        setDraggableIdSelect(result.source.index)
        const newItems = Array.from(chart);
        const [removed] = newItems.splice(result.source.index, 1);
        newItems.splice(result.destination.index, 0, removed);
        setChart(newItems);
    };

    const handleWorkDetailChange = (event) => {
        updateWidgetDetail({ ...widgetDetail, [event.target.name]: event.target.value });
        // setAutoComplete(true)
    }

    const deleteChart = (result) => {
        const newItems = Array.from(chart);
        newItems.splice(result, 1);
        setChart(newItems);
    }

    const deleteWidget = (result) => {
        const newItems = [...widgetSaved]
        let id = newItems.findIndex((item) => item.id === result);
        newItems.splice(id, 1, { id: null, graph: null });
        valid = true
        newItems.map((element) => {
            if (element.id !== null) {
                valid = false
            }
        })
        if (valid) {
            setWidget([])
            dispatch(deleteWidgetArray([]))
        }
        else {
            setWidget(newItems);
            dispatch(deleteWidgetArray(newItems))
        }
    }

    useEffect(() => {
        setOptions(widgetList)
    }, [widgetList])

    return (
        <LayoutContainer>
            <ContentWrapper>
                <InsightComponent
                    options={options}
                    onDragEnd={onDragEnd}
                    workflowName={workflowName}
                    deleteChart={deleteChart}
                    handleClick={handleClick}
                    handleClose={handleClose}
                    openChart={openChart}
                    widgetSaved={widgetSaved}
                    chart={chart}
                    draggableIdSelect={draggableIdSelect}
                    widgetList={widgetList}
                    widgetDetail={widgetDetail}
                    anchorEl={anchorEl}
                    TableData={TableData}
                    keplerChart={keplerChart}
                    bubbleChart={bubbleChart}
                    geoChart={geoChart}
                    timeLineChart={timeLineChart}
                    scatterChart={scatterChart}
                    pieChart={pieChart}
                    barChart={barChart}
                    lineChart={lineChart}
                    setInputValue={setInputValue}
                    inputValue={inputValue}
                    handleWorkDetailChange={handleWorkDetailChange}
                    addWidget={addWidget}
                    autoComplete={autoComplete}
                    widget={widget}
                    updateWidgetDetail={updateWidgetDetail}
                    deleteWidget={deleteWidget}
                />
            </ContentWrapper>
        </LayoutContainer >
    );
};

export default InsightContainer;
