import React, { useEffect, useState } from 'react';
import { Box, Button, useMediaQuery } from '@mui/material';
import { useDispatch, useSelector } from 'react-redux';
import { ViewSidebar as ViewSidebarIcon, Search as SearchIcon } from '@mui/icons-material';
import { INITIAL_CONFIG_DATA } from "./InitialConstants";
import {
    getConnectorList,
    createNewConnector,
    createNewJobDetail,
    getConfigurationList,
    getConnector,
    updateConnector,
    updateJobDetail,
    deleteConnector,
    deleteJobDetail,
    getConnectorType,
    getWorkflowType,
    getJobDetails,
    getSingleJobDetail,
    createWorflowName,
    removeWorkflow,
    workFlowNotExist,
    updateWorkflowNames,
    removeUpdatedWorkflow,
    ConnectorTabSelect,
    JobTabSelect,
} from '../../Actions/integrationAction';
import { CHOOSE_EXISTING_ONE, CONFIG, CONNECTOR, CREATE_NEW_ONE, CONNECTOR_TYPE } from '../../Constant';
import { LayoutContainer } from '..';
import { ConnectorComponent, JobDetailComponent, SidePanelComponent, SidePanelComponentMobile } from '../../Components/index';
import Styled from './style';

const INITIAL_CONNECTOR_DATA = {
    "AWS-S3": {
        connectorType: 'AWS-S3',
        connectorName: '',
        description: '',
        isSourceRequestPayer: false,
        region: '',
        accessKey: '',
        secretKey: '',
    },
    "Google Static API": {
        connectorType: 'Google Static API',
        connectorName: '',
        description: '',
        secret: '',
        apiKey: ''
    },
    "Maxar": {
        connectorType: 'Maxar',
        connectorName: '',
        description: '',
        deliveryPlatform: 'Google Cloud Storage',
        credentialId: ''
    },
    "Google Place Detail API": {
        connectorType: 'Google Place Detail API',
        connectorName: '',
        description: '',
        apiKey: ''
    },
    "Google Cloud Storage": {
        connectorType: 'Google Cloud Storage',
        connectorName: '',
        description: '',
    }
}

const INITIAL_JOB_DETAIL_DATA = {
    name: '',
    sourceBucketName: '',
    destinationBucketName: '',
    type: '',
    workflowName: '',
    status: 'PAUSE',
    chainSeqNo: '',
}

let basicFields = ["address_component", "adr_address", "business_status", "formatted_address", "geometry", "icon", "icon_mask_base_uri", "icon_background_color", "name", "photo", "place_id", "plus_code", "type", "url", "utc_offset", "vicinity"]
let contactFields = ["formatted_phone_number", "international_phone_number", "opening_hour", "website"]
let reviewFields = ["price_level", "rating", "review", "user_ratings_total"]

const IntegrationContainer = () => {
    const dispatch = useDispatch();
    const matches = useMediaQuery('(max-width:991px)');
    const [connectorDetailArr, updateConnectorDetailArr] = useState([]);
    const [connectorData, updateConnectorData] = useState(INITIAL_CONNECTOR_DATA['AWS-S3']);
    const [connectorError, updateConnectorError] = useState({});
    const [configData, updateConfigData] = useState(INITIAL_CONFIG_DATA['Google Static API']);
    const [jobDestination, updateJobDestination] = useState(INITIAL_CONFIG_DATA['AWS-S3'])
    const [jobDetailData, updateJobDetailData] = useState(INITIAL_JOB_DETAIL_DATA)
    const [jobDetailDataError, updateJobDetailDataError] = useState({});
    const [configError, updateConfigError] = useState({});
    const [jobDestinationError, updateJobDestinationError] = useState({});
    const [openDrawer, setOpenDrawer] = useState(false);
    const [tempBucketNameList, setTempBucketNameList] = useState([])
    const [showText, setShowText] = useState({ accessKey: false, secretKey: false, apiKey: false });
    const [openWorkflowEdits, setOpenWorkflowEdits] = useState(false)
    const [workflowNameChange, setWorkflowNameChange] = useState("")
    const { connectorList, singleJobDetail, jobList, connectorType, workFlowList, jobTabStatus, connectorTabStatus, workFlowUpdated, workFlowId, configurationList, singleConnector, singleConfig } = useSelector((state) => state.integrationReducer);

    useEffect(() => {
        dispatch(getConnectorList());
        dispatch(getConfigurationList());
        dispatch(getConnectorType());
        dispatch(getWorkflowType());
        dispatch(getJobDetails());
    }, [])

    useEffect(() => {
        if (singleConnector) {
            let singleConnectorDetail = singleConnector.detail && JSON.parse(singleConnector?.detail)
            let singleconnectorStore = {}
            if (singleConnector.connectorType.name === "AWS-S3") {
                singleconnectorStore = {
                    connectorType: singleConnector?.connectorType.name,
                    connectorName: singleConnector?.name,
                    description: singleConnector?.description,
                    isSourceRequestPayer: singleConnectorDetail?.isSourceRequestPayer,
                    accessKey: singleConnectorDetail?.accessKeyId,
                    secretKey: singleConnectorDetail?.secretKeyId,
                    region: singleConnectorDetail?.region,
                }
            }
            else if (singleConnector.connectorType.name === "Google Static API") {
                singleconnectorStore = {
                    connectorType: singleConnector?.connectorType.name,
                    connectorName: singleConnector?.name,
                    description: singleConnector?.description,
                    secret: singleConnectorDetail?.secret,
                    apiKey: singleConnectorDetail?.apiKey,
                }
            }
            else if (singleConnector.connectorType.name === "Google Place Detail API") {
                singleconnectorStore = {
                    connectorType: singleConnector?.connectorType.name,
                    connectorName: singleConnector?.name,
                    description: singleConnector?.description,
                    apiKey: singleConnectorDetail?.apiKey,
                }
            }
            else if (singleConnector.connectorType.name === "Maxar") {
                singleconnectorStore = {
                    connectorType: singleConnector?.connectorType.name,
                    connectorName: singleConnector?.name,
                    description: singleConnector?.description,
                    deliveryPlatform: singleConnectorDetail?.deliveryPlatform,
                    credentialId: singleConnectorDetail?.credentialId,
                }
            }
            else if (singleConnector.connectorType.name === "Google Cloud Storage") {
                singleconnectorStore = {
                    connectorType: singleConnector?.connectorType.name,
                    connectorName: singleConnector?.name,
                    description: singleConnector?.description,
                }
            }
            updateConnectorData(singleconnectorStore)
        }
    }, [singleConnector])

    useEffect(() => {
        if (singleJobDetail) {
            let singleJobDetails = singleJobDetail.detail && JSON.parse(singleJobDetail.detail)
            let jobDetail = {
                name: singleJobDetail.name,
                sourceBucketName: singleJobDetail.sourceConnectorDetail,
                destinationBucketName: singleJobDetail.destinationConnectorDetail,
                type: singleJobDetail.type,
                status: singleJobDetail.status,
                workflowName: singleJobDetail.workflow,
                chainSeqNo: singleJobDetail.chainSeqNo,
            };
            updateJobDetailData(jobDetail)
            updateJobDestination(singleJobDetails.destinationDetail)
            updateConfigData(singleJobDetails.sourceDetail)
        }
    }, [singleJobDetail])

    const handleJobCreateNew = () => {
        handleReset(CONFIG);
        dispatch(JobTabSelect(CREATE_NEW_ONE))
    }

    const handleCreateNew = () => {
        dispatch(ConnectorTabSelect(CREATE_NEW_ONE))
        handleReset(CONNECTOR);
    }

    const handleClickShowTextSecretKey = () => setShowText({ ...showText, secretKey: !showText.secretKey });
    const handleMouseDownPasswordSecretKey = () => setShowText({ ...showText, secretKey: !showText.secretKey });
    const handleClickShowTextAccessKey = () => setShowText({ ...showText, accessKey: !showText.accessKey });
    const handleMouseDownPasswordAccessKey = () => setShowText({ ...showText, accessKey: !showText.accessKey });
    const handleClickShowTextApiKey = () => setShowText({ ...showText, apiKey: !showText.apiKey });
    const handleMouseDownPasswordApikey = () => setShowText({ ...showText, apiKey: !showText.apiKey });

    const handleReset = (type) => {
        switch (type) {
            case CONNECTOR:
                updateConnectorData(INITIAL_CONNECTOR_DATA['AWS-S3']);
                updateConnectorError({});
                break;

            case CONFIG:
                updateConfigData(INITIAL_CONFIG_DATA['Google Static API']);
                updateJobDestination(INITIAL_CONFIG_DATA['AWS-S3']);
                updateJobDetailData(INITIAL_JOB_DETAIL_DATA);
                updateConfigError({});
                updateJobDestinationError({});
                updateJobDetailDataError({});
                dispatch(removeUpdatedWorkflow(null))
                setWorkflowNameChange("")
                break;

            default:
                return null;
        }
    }

    const selectBucketName = (e, i) => {
        let temp = [...connectorList?.message];
        // temp = [...connectorDetailArr];
        temp.splice(i, 1);
        setTempBucketNameList(temp)
    }

    const handleJobChooseExisting = () => {
        dispatch(JobTabSelect(CHOOSE_EXISTING_ONE))
        handleReset(CONFIG);
    }

    const handleChooseExisting = () => {
        dispatch(ConnectorTabSelect(CHOOSE_EXISTING_ONE))
        handleReset(CONNECTOR);
    }

    const handleConnectorChange = (event) => {
        if (event.target.name === CONNECTOR_TYPE) {
            updateConnectorData(INITIAL_CONNECTOR_DATA[event.target.value])
            updateConnectorError({});
        }
        else {
            if (event.target.type === "checkbox") {
                updateConnectorData({ ...connectorData, [event.target.name]: event.target.checked });
            } else {
                updateConnectorData({ ...connectorData, [event.target.name]: event.target.value });
            }
            updateConnectorError({ ...connectorError, [event.target.name]: false })
        }
    }

    const handleJobDetailChange = (event) => {
        if (event.target.type === "checkbox") {
            updateJobDetailData({ ...jobDetailData, [event.target.name]: event.target.checked });
        } else {
            setOpenWorkflowEdits(false);
            updateJobDetailData({ ...jobDetailData, [event.target.name]: event.target.value });
        }
        if (event.target.name === "sourceBucketName") {
            updateConfigData(INITIAL_CONFIG_DATA[event.target.value.connectorType.name])
        }
        else if (event.target.name === "destinationBucketName") {
            updateJobDestination(INITIAL_CONFIG_DATA[event.target.value.connectorType.name])
        }
        else if (event.target.name === "name") {
            updateConfigData(INITIAL_CONFIG_DATA['Google Static API'])
            updateJobDestination(INITIAL_CONFIG_DATA['AWS-S3'])
        }
        updateJobDetailDataError({ ...jobDetailDataError, [event.target.name]: false });
    }

    const handleJobDestinationChange = (event) => {
        updateJobDestination(INITIAL_CONFIG_DATA[jobDetailData?.destinationBucketName?.connectorType?.name])
        if (event.target.type === "checkbox") {
            updateJobDestination({ ...jobDestination, [event.target.name]: event.target.checked });
        } else {
            updateJobDestination({ ...jobDestination, [event.target.name]: event.target.value });
        }
        updateJobDestinationError({ ...jobDestinationError, [event.target.name]: false })
    }

    const handleConfigChange = (event) => {
        // handleTextError(configData);
        if (event.target.name === CONNECTOR_TYPE) {
            updateConfigData(INITIAL_CONFIG_DATA[event.target.value])
        } else {
            if (event.target.type === "checkbox") {
                updateConfigData({ ...configData, [event.target.name]: event.target.checked });
            } else {
                updateConfigData({ ...configData, [event.target.name]: event.target.value });
            }
        }
        updateConfigError({ ...configError, [event.target.name]: false });
    }

    const validateData = (data, type) => {
        let isValid = true;
        const errors = {};
        Object.keys(data).forEach((x) => {
            if (data[x].length === 0) {
                errors[x] = true;
                isValid = false;
            }
        })
        switch (type) {
            case CONNECTOR:
                updateConnectorError(errors);
                break;
            case CONFIG:
                updateJobDetailDataError(errors);
                break;
            default:
                return null;
        }
        return isValid;
    }

    const getFormattedData = (type) => {
        switch (type) {
            case CONNECTOR:
                let connectorTypeId = connectorType.message.find(o => o.name === connectorData?.connectorType)
                let connectorStore = {}
                if (connectorData.connectorType === "AWS-S3") {
                    connectorStore = {
                        connectorTypeId: connectorTypeId.connectorTypeId,
                        name: connectorData?.connectorName,
                        description: connectorData?.description,
                        detail: {
                            isSourceRequestPayer: connectorData?.isSourceRequestPayer,
                            accessKeyId: connectorData?.accessKey,
                            secretKeyId: connectorData?.secretKey,
                            region: connectorData?.region,
                        }
                    }
                }
                else if (connectorData.connectorType === "Google Static API") {
                    connectorStore = {
                        connectorTypeId: connectorTypeId.connectorTypeId,
                        name: connectorData?.connectorName,
                        description: connectorData?.description,
                        detail: {
                            secret: connectorData?.secret,
                            apiKey: connectorData?.apiKey,
                        }
                    }
                }
                else if (connectorData.connectorType === "Google Place Detail API") {
                    connectorStore = {
                        connectorTypeId: connectorTypeId.connectorTypeId,
                        name: connectorData?.connectorName,
                        description: connectorData?.description,
                        detail: {
                            apiKey: connectorData?.apiKey,
                        }
                    }
                }
                else if (connectorData.connectorType === "Maxar") {
                    connectorStore = {
                        connectorTypeId: connectorTypeId.connectorTypeId,
                        name: connectorData?.connectorName,
                        description: connectorData?.description,
                        detail: {
                            deliveryPlatform: connectorData?.deliveryPlatform,
                            credentialId: connectorData?.credentialId,
                        }
                    }
                }
                else if (connectorData.connectorType === "Google Cloud Storage") {
                    connectorStore = {
                        connectorTypeId: connectorTypeId.connectorTypeId,
                        name: connectorData?.connectorName,
                        description: connectorData?.description,
                        detail: {
                        }
                    }
                }

                return connectorStore;

            case CONFIG:
                let configStore = {}
                let jobData = {}
                if (jobTabStatus === CHOOSE_EXISTING_ONE) {
                    jobData = {
                        chainSeqNo: jobDetailData?.chainSeqNo ? parseInt(jobDetailData?.chainSeqNo) : "",
                        destinationConnectorDetailId: jobDetailData?.destinationBucketName ? jobDetailData?.destinationBucketName.connectorDetailId : "",
                        name: jobDetailData?.name,
                        sourceConnectorDetailId: jobDetailData?.sourceBucketName ? jobDetailData?.sourceBucketName.connectorDetailId : "",
                        type: jobDetailData?.type,
                        status: jobDetailData?.status,
                        workflowId: jobDetailData?.workflowName?.workflowId,
                    }
                }
                else {
                    jobData = {
                        chainSeqNo: jobDetailData?.chainSeqNo ? parseInt(jobDetailData?.chainSeqNo) : "",
                        destinationConnectorDetailId: jobDetailData?.destinationBucketName ? jobDetailData?.destinationBucketName.connectorDetailId : "",
                        name: jobDetailData?.name,
                        sourceConnectorDetailId: jobDetailData?.sourceBucketName ? jobDetailData?.sourceBucketName.connectorDetailId : "",
                        type: jobDetailData?.type,
                        status: jobDetailData?.status,
                        workflowId: workFlowId?.workflowId,
                    }
                }

                if (jobDetailData?.sourceBucketName?.connectorType?.name === "Google Static API" || jobDetailData?.sourceBucketName?.connectorType?.name === "Google Place Detail API" &&
                    jobDetailData?.destinationBucketName?.connectorType?.name === "AWS-S3") {
                    configStore = {
                        ...jobData,
                        detail: {
                            destinationDetail: {
                                ...jobDestination
                            },
                            sourceDetail: {
                                ...configData,
                            },
                        }
                    }
                }
                else if (jobDetailData?.sourceBucketName?.connectorType?.name === "Google Place Detail API" &&
                    jobDetailData?.destinationBucketName?.connectorType?.name === "Google Cloud Storage") {
                    let dataField = []
                    // configData?.contactInfo ? fields.push()
                    if (configData?.contactInfo && configData?.review) {
                        dataField = [...basicFields, ...contactFields, ...reviewFields]
                    }
                    else if (configData?.contactInfo) {
                        dataField = [...basicFields, ...contactFields]
                    }
                    else if (configData?.contactInfo) {
                        dataField = [...basicFields, ...reviewFields]
                    }
                    else {
                        dataField = [...basicFields]
                    }
                    configStore = {
                        ...jobData,
                        detail: {
                            destinationDetail: {
                                ...jobDestination
                            },
                            sourceDetail: {
                                address: configData?.address,
                                fields: dataField.toString()
                            },
                        }
                    }
                }
                else {
                    configStore = {
                        ...jobData
                    }
                }
                return (configStore);

            default:
                return null;
        }
    }

    const handleConnectorSave = () => {
        const isValid = validateData(connectorData, CONNECTOR);
        const formattedData = getFormattedData(CONNECTOR);
        let connectorTypeId = connectorType.message.find(o => o.name === formattedData.connectorType)
        let createConnector = { ...formattedData, ...connectorTypeId }
        if (isValid) {
            // Make create API call.
            dispatch(createNewConnector(formattedData));
            handleReset(CONNECTOR);
        }
    }

    const handleJobSave = () => {
        const formattedData = getFormattedData(CONFIG)
        const isValid = validateData(jobDetailData, CONFIG);
        if (isValid) {
            // Make create API call.
            if (workFlowId?.workflowId) {
                dispatch(createNewJobDetail(formattedData));
                dispatch(removeWorkflow(null))
                handleReset(CONFIG);
            }
            else {
                dispatch(workFlowNotExist())
            }
        }
    }
    const handleUpdate = (data, type) => {
        const isValid = validateData(data, type);
        if (isValid) {
            // Make upadte API call.
            switch (type) {
                case CONNECTOR:
                    const ConnectorformattedData = getFormattedData(CONNECTOR)
                    dispatch(updateConnector(singleConnector.connectorDetailId, ConnectorformattedData));
                    handleReset(CONNECTOR);
                    break;

                case CONFIG:
                    const ConfigformattedData = getFormattedData(CONFIG);
                    dispatch(updateJobDetail(singleJobDetail?.jobId, ConfigformattedData));
                    dispatch(removeUpdatedWorkflow(null))
                    handleReset(CONFIG);
                    break;

                default:
                    return null;
            }
        }
    }

    const handleDelete = (type) => {
        switch (type) {
            case CONNECTOR:
                dispatch(deleteConnector(singleConnector.connectorDetailId));
                handleReset(CONNECTOR);
                break;

            case CONFIG:
                dispatch(deleteJobDetail(singleJobDetail?.jobId));
                handleReset(CONFIG);
                break;

            default:
                return null;
        }
    }

    const createWorkflowName = () => {
        jobDetailData?.workflowName && dispatch(createWorflowName(jobDetailData?.workflowName))
    }

    const workflowNameUpdate = (e) => {
        setWorkflowNameChange(e.target.value)
    }

    const updateWorkflowName = () => {
        let data = { id: jobDetailData?.workflowName.workflowId, name: workflowNameChange }
        jobDetailData?.workflowName && workflowNameChange && dispatch(updateWorkflowNames(data))
        setOpenWorkflowEdits(false)
    }

    const openWorkflowEdit = (name) => {
        setWorkflowNameChange(name)
        setOpenWorkflowEdits(true)
    }

    const closeWorkflowEdit = () => {
        setOpenWorkflowEdits(false)
        // dispatch(getWorkflowType());
        dispatch(getJobDetails());
    }

    const getSingleConnector = (connectorDetail) => {
        dispatch(getConnector(connectorDetail));
    }

    const getSingleConfig = (jobDetail) => {
        dispatch(getSingleJobDetail(jobDetail));
    }

    const handleSidePanelDrawer = (flag) => {
        setOpenDrawer(flag);
    }

    return (
        <LayoutContainer>
            {matches ?
                <SidePanelComponentMobile
                    handleSidePanelDrawer={handleSidePanelDrawer}
                    openDrawer={openDrawer} />
                :
                <SidePanelComponent />
            }
            <Styled.ContentWrapper>
                {matches && (
                    <Styled.ButtonWrapper>
                        <Styled.Search>
                            <Styled.StyledInputBase fullWidth placeholder="Search…" inputProps={{ 'aria-label': 'search' }} />
                            <Styled.SearchIconWrapper>
                                <SearchIcon />
                            </Styled.SearchIconWrapper>
                        </Styled.Search>
                    </Styled.ButtonWrapper>
                )}
                {/* <Styled.ButtonWrapper>
                    <Grid container>
                        <Grid style={{ display: 'flex', alignItems: 'center', }} item xs={3} sm={3} md={4}>
                            {matches && <Styled.MenuIcon onClick={() => handleSidePanelDrawer(true)}><ViewSidebarIcon fontSize='large' /></Styled.MenuIcon>}
                        </Grid>
                        <Grid style={{ display: 'flex', alignItems: 'center', justifyContent: !matches ? 'center' : 'flex-start', }} item xs={matches ? 9 : 12} sm={matches ? 9 : 12} md={matches ? 8 : 12}>
                            <Styled.ButtonGroupWrapper>
                                <Button className={connectorTabOption === CREATE_NEW_ONE ? 'active' : ''} onClick={handleCreateNew}>Create New One</Button>
                                <Button className={connectorTabOption === CHOOSE_EXISTING_ONE ? 'active' : ''} onClick={handleChooseExisting}>Choose Existing One</Button>
                            </Styled.ButtonGroupWrapper>
                        </Grid>
                    </Grid>
                </Styled.ButtonWrapper> */}
                <Styled.ConfigurationWrapper>
                    <Box>
                        <Styled.PaperWrapper>
                            <ConnectorComponent
                                matches={matches}
                                handleSidePanelDrawer={handleSidePanelDrawer}
                                handleCreateNew={handleCreateNew}
                                connectorTabOption={connectorTabStatus}
                                handleChooseExisting={handleChooseExisting}
                                handleConnectorChange={handleConnectorChange}
                                connectorError={connectorError}
                                connectorData={connectorData}
                                connectorList={connectorList}
                                connectorType={connectorType}
                                showText={showText}
                                getSingleConnector={getSingleConnector}
                                handleClickShowTextSecretKey={handleClickShowTextSecretKey}
                                handleMouseDownPasswordSecretKey={handleMouseDownPasswordSecretKey}
                                handleClickShowTextAccessKey={handleClickShowTextAccessKey}
                                handleMouseDownPasswordAccessKey={handleMouseDownPasswordAccessKey}
                                handleClickShowTextApiKey={handleClickShowTextApiKey}
                                handleMouseDownPasswordApikey={handleMouseDownPasswordApikey}
                            />
                            <Styled.ButtonBox>
                                {connectorTabStatus === CREATE_NEW_ONE && <Button onClick={handleConnectorSave}>SAVE</Button>}
                                {connectorTabStatus === CHOOSE_EXISTING_ONE && <Button onClick={() => handleUpdate(connectorData, CONNECTOR)}>UPDATE</Button>}
                                {connectorTabStatus === CHOOSE_EXISTING_ONE && <Button onClick={() => handleDelete(CONNECTOR)}>DELETE</Button>}
                                <Button onClick={() => handleReset(CONNECTOR)}>RESET</Button>
                            </Styled.ButtonBox>
                        </Styled.PaperWrapper>
                        <Styled.PaperWrapper>
                            <JobDetailComponent
                                basicFields={basicFields}
                                connectorTabOption={jobTabStatus}
                                handleJobDetailChange={handleJobDetailChange}
                                jobDetailDataError={jobDetailDataError}
                                jobDetailData={jobDetailData}
                                jobDetailTabOption={jobTabStatus}
                                handleJobChooseExisting={handleJobChooseExisting}
                                handleJobCreateNew={handleJobCreateNew}
                                connectorList={connectorList}
                                connectorDetailArr={connectorDetailArr}
                                selectBucketName={selectBucketName}
                                workFlowList={workFlowList}
                                tempBucketNameList={tempBucketNameList}
                                handleConfigChange={handleConfigChange}
                                jobDestination={jobDestination}
                                configError={configError}
                                jobDestinationError={jobDestinationError}
                                configData={configData}
                                matches={matches}
                                handleSidePanelDrawer={handleSidePanelDrawer}
                                handleJobDestinationChange={handleJobDestinationChange}
                                jobList={jobList}
                                workflowNameChange={workflowNameChange}
                                workflowNameUpdate={workflowNameUpdate}
                                closeWorkflowEdit={closeWorkflowEdit}
                                openWorkflowEdits={openWorkflowEdits}
                                openWorkflowEdit={openWorkflowEdit}
                                workFlowId={workFlowId}
                                workFlowUpdated={workFlowUpdated}
                                createWorkflowName={createWorkflowName}
                                updateWorkflowName={updateWorkflowName}
                                getSingleConfig={getSingleConfig} />
                            <Styled.ButtonBox>
                                {jobTabStatus === CREATE_NEW_ONE && <Button onClick={handleJobSave} >SAVE</Button>}
                                {jobTabStatus === CHOOSE_EXISTING_ONE && <Button onClick={() => handleUpdate(jobDetailData, CONFIG)}>UPDATE</Button>}
                                {jobTabStatus === CHOOSE_EXISTING_ONE && <Button onClick={() => handleDelete(CONFIG)}>DELETE</Button>}
                                <Button onClick={() => handleReset(CONFIG)}>RESET</Button>
                            </Styled.ButtonBox>
                        </Styled.PaperWrapper>
                    </Box>
                </Styled.ConfigurationWrapper>
            </Styled.ContentWrapper>
        </LayoutContainer>
    );
};

export default IntegrationContainer;
