import React from 'react';
import { Button } from '@mui/material';
import { Link } from 'react-router-dom';
import { Notifications as NotificationsIcon } from '@mui/icons-material';
import CommonStyled from '../CommonStyle';
import Styled from "./style";
import { ViewSidebar as ViewSidebarIcon, Search as SearchIcon } from '@mui/icons-material';
import { Auth } from 'aws-amplify';

const HeaderComponent = () => {
    const logout = async () => {
        Auth.signOut()
    }
    return (
        <CommonStyled.HeaderContentWrapper>
            <CommonStyled.MenuWrapper>
                <Button>
                    <CommonStyled.Image src="images/galileosense.jpg"></CommonStyled.Image>
                </Button>
                <CommonStyled.HeaderMenu>
                    <Link to="/insight">Insight</Link>
                    <Link to="/integration">Integration</Link>
                    <Link to="/intelligence">Intelligence</Link>
                    <Link to="/ignition">Ignition</Link>
                    <Link to='#' style={{ marginLeft: "20vw" }} onClick={logout}>Logout</Link>
                </CommonStyled.HeaderMenu>
            </CommonStyled.MenuWrapper>
            <Styled.ButtonWrapper>
                <Styled.Search>
                    <Styled.StyledInputBase
                        fullWidth
                        placeholder="Search…"
                        inputProps={{ 'aria-label': 'search' }} />
                    <Styled.SearchIconWrapper>
                        <SearchIcon />
                    </Styled.SearchIconWrapper>
                </Styled.Search>
            </Styled.ButtonWrapper>
            <CommonStyled.NotificationWrapper>
                <NotificationsIcon />
            </CommonStyled.NotificationWrapper>
        </CommonStyled.HeaderContentWrapper>
    );
};

export default HeaderComponent;
