import React, { useEffect, useState } from 'react';
import { Box, Button, useMediaQuery, Grid, TextField, InputLabel } from '@mui/material';
import { useDispatch, useSelector } from 'react-redux';
import { ViewSidebar as ViewSidebarIcon, Search as SearchIcon } from '@mui/icons-material';
import { INITIAL_JOB_DATA } from "./InitialConstants";
import { getJobList, createNewJobDetail, getJob, deleteJob, updateJob, postNotebookDetails } from '../../Actions/integrationAction';
import { JOB } from '../../Constant';
import { LayoutContainer } from '../../Containers';
import { JobComponent } from '../../Components';
import Styled from './style';
import { CHOOSE_EXISTING_ONE, CREATE_NEW_ONE } from '../../Constant';
import { getConnectorList, getConfigurationList } from '../../Actions/integrationAction';
import { Dropzone, FileItem } from "@dropzone-ui/react";

const IntelligenceContainer = () => {
    const dispatch = useDispatch();
    const isTabOrMobile = useMediaQuery('(max-width:991px)');
    const [SelectedButtonOption, updateSelectedButtonOption] = useState(CREATE_NEW_ONE);
    const [jobError, updateJobError] = useState({});
    const [jobData, updateJobData] = useState(INITIAL_JOB_DATA);
    const [openDrawer, setOpenDrawer] = useState(false)
    const [files, setFiles] = useState([]);
    const [fileName, setFileName] = useState([]);
    const [fileDrop, setFileDrop] = useState(true)

    const { connectorList, configurationList } = useSelector((state) => state.integrationReducer);
    const { jobList, singleJob } = useSelector((state) => state.intelligenceReducer);

    useEffect(() => {
        dispatch(getJobList());
        dispatch(getConnectorList());
        dispatch(getConfigurationList());
    }, [])

    useEffect(() => {
        if (singleJob) {
            updateJobData({
                jobId: singleJob.jobId,
                configId: singleJob.configId,
                connectorId: singleJob.connectorId,
                jobName: singleJob.jobName,
                jobStatus: singleJob.jobStatus,
                jobType: singleJob.jobType,
                jobActiveInactive: singleJob.jobActiveInactive,
            })
        }
    }, [singleJob]);

    const handleReset = () => {
        updateJobData(INITIAL_JOB_DATA);
        updateJobError({});
    }

    const handleCreateNew = () => {
        handleReset();
        updateSelectedButtonOption(CREATE_NEW_ONE);
    }

    const handleChooseExisting = () => {
        dispatch(getJobList());
        updateSelectedButtonOption(CHOOSE_EXISTING_ONE);
        handleReset();
    }

    const handleChange = (event) => {
        updateJobData({ ...jobData, [event.target.name]: event.target.value });
        updateJobError({ ...jobError, [event.target.name]: false });
    }

    const validateData = (data, type) => {
        let isValid = true;
        const errors = {};
        Object.keys(data).forEach((x) => {
            if (data[x].length === 0) {
                errors[x] = true;
                isValid = false;
            }
        })
        updateJobError(errors);
        return isValid;
    }

    const getFormattedData = (type) => {
        return {
            jobId: jobData?.jobId,
            configId: jobData?.configId,
            connectorId: jobData?.connectorId,
            jobStatus: jobData?.jobStatus,
            jobName: jobData?.jobName,
            jobType: jobData?.jobType,
            jobActiveInactive: jobData?.jobActiveInactive,
            notebookName: fileName,
        }
    }
    const getFormattedDataSave = (type) => {
        return {
            jobId: jobData?.jobId,
            configId: jobData?.configId,
            connectorId: jobData?.connectorId,
            jobName: jobData?.jobName,
            jobType: jobData?.jobType,
            jobActiveInactive: jobData?.jobActiveInactive,
            notebookName: fileName,
        }
    }

    const handleSave = () => {
        delete jobData.jobStatus;
        const isValid = validateData(jobData, JOB);
        if (isValid) {
            // Make create API call.
            dispatch(createNewJobDetail(getFormattedDataSave(JOB)));
            handleReset(JOB);
        }
    }

    const handleUpdate = (data, type) => {
        const isValid = validateData(data, type);
        if (isValid) {
            // Make upadte API call.
            dispatch(updateJob(singleJob.jobId, data))
            handleReset(JOB)
        }

    };

    const handleDelete = (id) => {
        dispatch(deleteJob(singleJob.jobId));
        handleReset(JOB);
    };

    const getSingleJob = (id) => {
        dispatch(getJob(id));
    }

    const handleSidePanelDrawer = (flag) => {
        setOpenDrawer(flag);
    };

    const updateFiles = (incommingFiles) => {
        const data = new FormData();
        setFiles(incommingFiles);
        setFileName(incommingFiles[0].file.name)
        setFileDrop(false)
        const file = incommingFiles;
        data.append("file", file[0].file);
        dispatch(postNotebookDetails(data))
    };
    const onDelete = (id) => {
        setFiles(files.filter((x) => x.id !== id));
        setFileName("")
        setFileDrop(true)
    };

    return (
        <LayoutContainer>
            <Styled.ContentWrapper>
                {isTabOrMobile && (
                    <Styled.ButtonWrapper>
                        <Styled.Search>
                            <Styled.StyledInputBase fullWidth placeholder="Search…" inputProps={{ 'aria-label': 'search' }} />
                            <Styled.SearchIconWrapper>
                                <SearchIcon />
                            </Styled.SearchIconWrapper>
                        </Styled.Search>
                    </Styled.ButtonWrapper>
                )}
                <Grid container spacing={2}>
                    <Grid item xs={4}>
                        <Styled.ButtonWrapper>
                            <Grid container>
                                <Grid style={{ display: 'flex', alignItems: 'center', }} item xs={3} sx={3} md={4}>
                                    {isTabOrMobile && <Styled.MenuIcon onClick={() => handleSidePanelDrawer(true)}><ViewSidebarIcon fontSize='large' /></Styled.MenuIcon>}
                                </Grid>
                                <Grid style={{ display: 'flex', alignItems: 'center', justifyContent: !isTabOrMobile ? 'center' : 'flex-start', }} item xs={isTabOrMobile ? 9 : 12} sx={isTabOrMobile ? 9 : 12} md={isTabOrMobile ? 8 : 12}>
                                    <Styled.ButtonGroupWrapper>
                                        <Button className={SelectedButtonOption === CREATE_NEW_ONE ? 'active' : ''} onClick={handleCreateNew}>Create New One</Button>
                                        <Button className={SelectedButtonOption === CHOOSE_EXISTING_ONE ? 'active' : ''} onClick={handleChooseExisting}>Choose Existing One</Button>
                                    </Styled.ButtonGroupWrapper>
                                </Grid>
                            </Grid>
                        </Styled.ButtonWrapper>
                        <Styled.JobWrapper>
                            <Box>
                                <Styled.PaperWrapper>
                                    <JobComponent
                                        SelectedButtonOption={SelectedButtonOption}
                                        handleJobChange={handleChange}
                                        jobError={jobError}
                                        jobData={jobData}
                                        jobList={jobList}
                                        connectorList={connectorList}
                                        configurationList={configurationList}
                                        getJob={getSingleJob}
                                        notebookName={fileName} />
                                    <Styled.ButtonBox>
                                        {SelectedButtonOption === CREATE_NEW_ONE && <Button onClick={handleSave}>SAVE</Button>}
                                        {SelectedButtonOption === CHOOSE_EXISTING_ONE && <Button onClick={() => handleUpdate(jobData, JOB)}>UPDATE</Button>}
                                        {SelectedButtonOption === CHOOSE_EXISTING_ONE && <Button onClick={() => handleDelete(JOB)}>DELETE</Button>}
                                        <Button onClick={() => handleReset(JOB)}>RESET</Button>
                                    </Styled.ButtonBox>
                                </Styled.PaperWrapper>
                            </Box>
                        </Styled.JobWrapper>
                        <Dropzone
                            onChange={updateFiles}
                            minHeight="180px"
                            value={files}
                            maxFiles={1}
                            header={false}
                            clickable={fileDrop}
                            accept=".ipynb"
                            disableScroll
                        >
                            {files.map((file) => (
                                <FileItem
                                    {...file}
                                    key={file.id}
                                    onDelete={onDelete}
                                    resultOnTooltip
                                    preview
                                    info
                                    hd
                                />
                            ))}
                        </Dropzone>
                    </Grid >
                    <Grid item xs={8}>
                        <iframe src='https://jupyterlite.readthedocs.io/en/latest/_static/lab/index.html' style={{ height: "100%", width: "100%" }} />
                    </Grid>
                </Grid >
            </Styled.ContentWrapper>
        </LayoutContainer>
    );
};

export default IntelligenceContainer;

