import { takeEvery } from 'redux-saga/effects';
import {
    REQUEST_CONFIG_LIST,
    REQUEST_CONNECTOR_LIST,
    REQUEST_CREATE_JOB_DETAIL,
    REQUEST_CREATE_CONNECTOR,
    REQUEST_DELETE_CONNECTOR,
    REQUEST_UPDATE_CONNECTOR,
    REQUEST_SNACKBAR_CONFIG,
    //JOB
    REQUEST_JOB_LIST,
    REQUEST_CREATE_JOB,
    REQUEST_SINGLE_JOB,
    REQUEST_UPDATE_JOB,
    REQUEST_DELETE_JOB,
    //JOB STATUS
    REQUEST_UPDATE_JOB_STATUS_ABORT,
    POST_NOTEBOOK_DETAILS,
    REQUEST_CONNECTOR_TYPE,
    REQUEST_WORKFLOW_TYPE,
    REQUEST_JOB_DETAILS,
    REQUEST_UPDATE_JOB_DETAIL,
    REQUEST_DELETE_JOB_DETAIL,
    REQUEST_WIDGET_DETAILS,
    REQUEST_CREATE_WORKFLOW,
    UPDATE_WORKFLOW_NAME,
    REQUEST_UPDATE_JOB_STATUS_RUN,
    REQUEST_WORKFLOW_NAME,
} from '../Constant';
import { updateJobDetailStatusAbort, updateJobDetailStatusRun } from './IgnitionSaga';
import { getWidgetDetailList, getWorkflowName } from './insightSaga';

import {
    createConfig,
    getConfigs,
    updateJobDetailRecord,
    deleteJobDetailRecord,
    createConnector,
    getConnectors,
    getConnectorType,
    updateConnectorRecord,
    deleteConnectorRecord,
    handleSnackBarClose,
    getConnector,
    getWorkFlow,
    getJobDetail,
    createWorkflow,
    updateWorkName,
} from './integrationSaga';

import {
    getJobs,
    createJob,
    getJob,
    updateJobRecord,
    deleteJobRecord,
    updateJobStatusAbort,
    updateJobStatusPause,
    postNotebookDetail,
    updateJobStatusResume,
} from './intelligenceSaga';

function* rootSagas() {
    // Get list of all records
    yield takeEvery(REQUEST_CONNECTOR_LIST, getConnectors);
    yield takeEvery(REQUEST_CONNECTOR_TYPE, getConnectorType);
    yield takeEvery(REQUEST_CONFIG_LIST, getConfigs);
    yield takeEvery(REQUEST_JOB_LIST, getJobs);
    yield takeEvery(REQUEST_WORKFLOW_TYPE, getWorkFlow);
    yield takeEvery(REQUEST_WIDGET_DETAILS, getWidgetDetailList)

    // Create new record
    yield takeEvery(REQUEST_CREATE_CONNECTOR, createConnector);
    yield takeEvery(REQUEST_CREATE_JOB_DETAIL, createConfig);
    yield takeEvery(REQUEST_CREATE_JOB, createJob);
    yield takeEvery(REQUEST_CREATE_WORKFLOW, createWorkflow)

    // Get single record
    // yield takeEvery(REQUEST_SINGLE_CONNECTOR, getConnector);
    yield takeEvery(REQUEST_JOB_DETAILS, getJobDetail)
    yield takeEvery(REQUEST_SINGLE_JOB, getJob);
    // Update record
    yield takeEvery(REQUEST_UPDATE_CONNECTOR, updateConnectorRecord);
    yield takeEvery(REQUEST_UPDATE_JOB_DETAIL, updateJobDetailRecord);
    yield takeEvery(REQUEST_UPDATE_JOB, updateJobRecord);
    // Delete record
    yield takeEvery(REQUEST_DELETE_CONNECTOR, deleteConnectorRecord);
    yield takeEvery(REQUEST_DELETE_JOB_DETAIL, deleteJobDetailRecord);
    yield takeEvery(REQUEST_DELETE_JOB, deleteJobRecord);

    //Get Workflow Name Insight
    yield takeEvery(REQUEST_WORKFLOW_NAME, getWorkflowName);

    //Ignition Saga
    yield takeEvery(REQUEST_UPDATE_JOB_STATUS_ABORT, updateJobDetailStatusAbort)
    yield takeEvery(REQUEST_UPDATE_JOB_STATUS_RUN, updateJobDetailStatusRun)

    yield takeEvery(UPDATE_WORKFLOW_NAME, updateWorkName)
    yield takeEvery(POST_NOTEBOOK_DETAILS, postNotebookDetail)

    yield takeEvery(REQUEST_SNACKBAR_CONFIG, handleSnackBarClose);
}

export default rootSagas;