import React, { useEffect, useState } from 'react'
import Plot from 'react-plotly.js'

const BubbleChart = () => {
    const [data, setData] = useState({
        value: [],
        layout: null,
        update: null,
    });

    useEffect(() => {
        fetch('/chart_data.json')
            .then(response => response.json())
            .then((jsonData) => {
                const chartData = jsonData.bubblechart
                let values = []
                chartData.data.map((data) => {
                    values.push(data)
                })
                setData({ ...data, value: values, layout: chartData.layout, update: chartData.update });
            })
            .catch((error) => {
                console.error("Unabled to fetch json")
            })
    }, []);

    return (
        <>
            <Plot
                data={data.value}
                layout={data.layout}
                update={data.update}
            />
        </>
    )
}

export default BubbleChart