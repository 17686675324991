import { createStore, applyMiddleware, compose } from 'redux';
import createSagaMiddleware from 'redux-saga'
import rootReducer from './Reducers';
import rootSagas from './Sagas/rootSaga';
import { taskMiddleware } from "react-palm/tasks";
import { persistStore, persistReducer } from "redux-persist";
import storage from "redux-persist/lib/storage";

const rootConfig = {
    key: "root",
    storage,
    whitelist: [],
};

const persistedReducer = persistReducer(rootConfig, rootReducer);

const sagaMiddleware = createSagaMiddleware();

const store = compose(
    applyMiddleware(sagaMiddleware, taskMiddleware)
)(createStore)(persistedReducer);
sagaMiddleware.run(rootSagas);


let persistor = persistStore(store);

export { store, persistor };
export default {};