import axios from "axios";
import { REACT_APP_API_URL_V1, REACT_APP_API_URL_V2 } from "./environment";

const axiosV1 = axios.create({
    baseURL: REACT_APP_API_URL_V1,
});

const axiosV2 = axios.create({
    baseURL: REACT_APP_API_URL_V2,
});

axiosV1.defaults.headers.post['Content-Type'] = 'application/json';
axiosV1.defaults.headers.common['Authorization'] = "AUTH_TOKEN";

axiosV2.defaults.headers.post['Content-Type'] = 'application/json';
axiosV2.defaults.headers.common['Authorization'] = "AUTH_TOKEN";

export { axiosV1, axiosV2 };
