import { styled } from '@mui/system';

export const Footer = styled('div')({
    background: '#040b24',
    padding: '20px 20px',
    button: {
        background: '#e5e5e5',
        borderRadius: '0',
        marginRight: '30px',
        padding: '12px 20px',
        color: '#000',
        fontSize: '12px',
        "&:hover": {
            background: '#e5e5e5',
        }
    }
});
