import {

    //CONFIG
    REQUEST_CREATE_JOB_DETAIL,
    REQUEST_CONFIG_LIST,
    REQUEST_SINGLE_CONFIG,
    REQUEST_UPDATE_CONFIG,
    REQUEST_DELETE_CONFIG,
    //CONNECTOR
    REQUEST_CONNECTOR_LIST,
    REQUEST_CREATE_CONNECTOR,
    REQUEST_SINGLE_CONNECTOR,
    REQUEST_UPDATE_CONNECTOR,
    REQUEST_DELETE_CONNECTOR,
    //JOB
    REQUEST_JOB_LIST,
    REQUEST_CREATE_JOB,
    REQUEST_SINGLE_JOB,
    REQUEST_UPDATE_JOB,
    REQUEST_DELETE_JOB,
    //JOB STATUS UPDATE
    REQUEST_UPDATE_JOB_STATUS_ABORT,
    REQUEST_UPDATE_JOB_STATUS_PAUSE,
    REQUEST_UPDATE_JOB_STATUS_STOP,
    //SNACKBAR
    REQUEST_SNACKBAR_CONFIG,
    POST_NOTEBOOK_DETAILS,
    REQUEST_CONNECTOR_TYPE,
    REQUEST_WORKFLOW_TYPE,
    REQUEST_JOB_DETAILS,
    REQUEST_SINGLE_JOB_DETAIL,
    GET_SINGLE_JOB_DETAIL,
    REQUEST_UPDATE_JOB_DETAIL,
    REQUEST_DELETE_JOB_DETAIL,
    REQUEST_WIDGET_DETAILS,
    REQUEST_CREATE_WORKFLOW,
    REMOVE_WORKFLOW_NAME,
    WORKFLOW_NOT_EXIST,
    UPDATE_WORKFLOW_NAME,
    SAVE_WIDGET_DETAILS,
    REQUEST_UPDATE_JOB_STATUS_RUN,
    DELETE_WIDGET_ARRAY_DETAILS,
    REMOVE_UPDATED_WORKFLOW_NAME,
    START_JOB_STATUS_LOADER,
    SELECT_CONNECTOR_TAB_STATUS,
    SELECT_JOB_TAB_STATUS,
    REQUEST_WORKFLOW_NAME,
} from "../Constant";

//CONFIG

export const getConfigurationList = () => {
    return { type: REQUEST_CONFIG_LIST }
}
export const createNewJobDetail = (data) => {
    return { type: REQUEST_CREATE_JOB_DETAIL, payload: data }
}
export const updateJobDetail = (id, data) => {
    return { type: REQUEST_UPDATE_JOB_DETAIL, payload: { id, data } }
}
export const deleteJobDetail = (id) => {
    return { type: REQUEST_DELETE_JOB_DETAIL, payload: id }
}

//CONNECTOR
export const getConnectorList = () => {
    return { type: REQUEST_CONNECTOR_LIST }
}
export const getConnectorType = () => {
    return { type: REQUEST_CONNECTOR_TYPE }
}
export const createNewConnector = (data) => {
    return { type: REQUEST_CREATE_CONNECTOR, payload: data }
}
export const removeWorkflow = (data) => {
    return { type: REMOVE_UPDATED_WORKFLOW_NAME, payload: data }
}
export const removeUpdatedWorkflow = (data) => {
    return { type: REMOVE_UPDATED_WORKFLOW_NAME, payload: data }
}
export const workFlowNotExist = () => {
    return { type: WORKFLOW_NOT_EXIST, payload: { open: true, severity: 'error', message: 'Please add workflow name first!' } }
}
export const updateWorkflowNames = (data) => {
    return { type: UPDATE_WORKFLOW_NAME, payload: data }
}
export const getConnector = (data) => {
    return { type: REQUEST_SINGLE_CONNECTOR, payload: data }
}
export const getSingleJobDetail = (data) => {
    return { type: GET_SINGLE_JOB_DETAIL, payload: data }
}
export const updateConnector = (connectorDetailId, data) => {
    return { type: REQUEST_UPDATE_CONNECTOR, payload: { connectorDetailId, data } }
}
export const deleteConnector = (id) => {
    return { type: REQUEST_DELETE_CONNECTOR, payload: id }
}
export const ConnectorTabSelect = (data) => {
    return { type: SELECT_CONNECTOR_TAB_STATUS, payload: data }
}
export const JobTabSelect = (data) => {
    return { type: SELECT_JOB_TAB_STATUS, payload: data }
}

//SNACKBAR
export const snackBarCloseConfig = () => {
    return { type: REQUEST_SNACKBAR_CONFIG }
}
// WorkFlow
export const getWorkflowType = () => {
    return { type: REQUEST_WORKFLOW_TYPE }
}
export const createWorflowName = (data) => {
    return { type: REQUEST_CREATE_WORKFLOW, payload: data }
}
export const getJobDetails = () => {
    return { type: REQUEST_JOB_DETAILS }
}
//JOB
export const getJobList = () => {
    return { type: REQUEST_JOB_LIST };
};
export const createNewJob = (data) => {
    return { type: REQUEST_CREATE_JOB, payload: data };
};
export const postNotebookDetails = (data) => {
    return { type: POST_NOTEBOOK_DETAILS, payload: data };
};
export const getJob = (data) => {
    return { type: REQUEST_SINGLE_JOB, payload: data };
};
export const deleteJob = (id) => {
    return { type: REQUEST_DELETE_JOB, payload: id };
};
export const updateJob = (id, data) => {
    return { type: REQUEST_UPDATE_JOB, payload: { id, data } };
};

//Ignite 
export const updateJobStatusRun = (data) => {
    return { type: REQUEST_UPDATE_JOB_STATUS_RUN, payload: { data } }
}
export const updateJobStatusAbort = (data) => {
    return { type: REQUEST_UPDATE_JOB_STATUS_ABORT, payload: { data } };
};
export const statusLoading = (jobId) => {
    return { type: START_JOB_STATUS_LOADER, payload: { jobId } }
};

//update job status
// export const updateJobStatusAbort = (data) => {
//     return { type: REQUEST_UPDATE_JOB_STATUS_ABORT, payload: { data } };
// };
export const updateJobStatusPause = (data) => {
    return { type: REQUEST_UPDATE_JOB_STATUS_PAUSE, payload: { data } };
};
export const updateJobStatusStop = (data) => {
    return { type: REQUEST_UPDATE_JOB_STATUS_STOP, payload: { data } };
};
export const updateJobStatusResume = (data) => {
    return { type: REQUEST_UPDATE_JOB_STATUS_STOP, payload: { data } }
}

export const getWorkflowNames = () => {
    return { type: REQUEST_WORKFLOW_NAME }
}
//Widget
export const getWidgetDetails = () => {
    return { type: REQUEST_WIDGET_DETAILS }
}
export const widgetArray = (data) => {
    return { type: SAVE_WIDGET_DETAILS, payload: data }
}
export const deleteWidgetArray = (data) => {
    return { type: DELETE_WIDGET_ARRAY_DETAILS, payload: data }
}