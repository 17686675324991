import { put, call } from "redux-saga/effects";
import { axiosV1, axiosV2 } from "../axiosConfig";
import {    //JOB
    GET_JOB_LIST,
    POST_JOB_DATA_RECORD,
    GET_SINGLE_JOB_RECORD,
    UPDATE_SINGLE_JOB_RECORD,
    DELETE_SINGLE_JOB_RECORD,

    //SNACKBAR
    SNACKBAR_CONFIG,
} from "../Constant";

export function* getJobs() {
    try {
        const { data } = yield call(axiosV1.get, '/job/list');
        return yield put({ type: GET_JOB_LIST, payload: data });

    } catch (error) {
        yield put({ type: SNACKBAR_CONFIG, payload: { open: true, severity: 'error', message: error.message } });
    }
};

export function* createJob({ payload }) {
    try {
        const response = yield call(axiosV1.post, 'job/create/', payload);
        if (response) {
            yield put({ type: SNACKBAR_CONFIG, payload: { open: true, severity: 'success', message: 'Job Created Successfully!' } });
        }
        return yield put({ type: POST_JOB_DATA_RECORD, payload: response });
    } catch (error) {
        yield put({ type: SNACKBAR_CONFIG, payload: { open: true, severity: 'error', message: error.message } });
    }
};


export function* getJob({ payload }) {
    try {
        const { data } = yield call(axiosV1.get, 'job/get/' + payload);
        return yield put({ type: GET_SINGLE_JOB_RECORD, payload: data });
    } catch (error) {
        yield put({ type: SNACKBAR_CONFIG, payload: { open: true, severity: 'error', message: error.message } });
    }
};

export function* updateJobRecord({ payload }) {
    try {
        const { data } = yield call(axiosV1.post, 'job/update/' + payload.id, payload.data);
        if (data) {
            yield put({ type: SNACKBAR_CONFIG, payload: { open: true, severity: 'success', message: 'Job Updated Successfully!' } });
        }
        return yield put({ type: UPDATE_SINGLE_JOB_RECORD, payload: data });
    } catch (error) {
        yield put({ type: SNACKBAR_CONFIG, payload: { open: true, severity: 'error', message: error.message } });
    }
};

export function* deleteJobRecord({ payload }) {
    try {
        yield call(axiosV1.delete, 'job/delete/' + payload);
        yield put({ type: SNACKBAR_CONFIG, payload: { open: true, severity: 'success', message: 'Job Deleted Successfully!!' } });
        return yield put({ type: DELETE_SINGLE_JOB_RECORD, payload: payload });
    } catch (error) {
        yield put({ type: SNACKBAR_CONFIG, payload: { open: true, severity: 'error', message: error.message } });
    }
};

export function* postNotebookDetail({ payload }) {
    try {

    } catch (error) {
        yield put({ type: SNACKBAR_CONFIG, payload: { open: true, severity: 'error', message: error.message } });
    }
};
