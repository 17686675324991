import React from 'react';
import { Grid, InputLabel, TextField, Radio, RadioGroup, FormControlLabel, MenuItem, Select, FormHelperText } from '@mui/material';
import Styled from '../../Components/CommonStyle';
import { CHOOSE_EXISTING_ONE, CREATE_NEW_ONE, JOB_STATUS_LIST, JOB_TYPE_LIST } from '../../Constant';

const JobComponent = (props) => {
    const { handleJobChange, jobError, jobData, connectorList, configurationList, SelectedButtonOption, jobList, getJob, notebookName } = props;

    return (
        <Grid container style={{ padding: '15px' }} spacing={2}>
            {SelectedButtonOption === CHOOSE_EXISTING_ONE ? (
                <Grid item lg={12} md={12} sm={12} xs={12} >
                    <Styled.InputWrapper>
                        <InputLabel>Select JOB</InputLabel>
                        <Select
                            fullWidth
                            name="existingJobs"
                            value={jobData.existingJobs}
                            onChange={handleJobChange}
                            error={jobError.existingJobs}>
                            {jobList && jobList.length > 0 && jobList.map((x, i) => {
                                return <MenuItem
                                    key={i}
                                    value={x.jobId}
                                    onClick={() => getJob(x.jobId)} >
                                    {x.jobId} | {x.jobType}
                                </MenuItem>
                            })}
                        </Select>
                    </Styled.InputWrapper>
                </Grid>) : ''}
            <Grid item lg={12} md={12} sm={12} xs={12}>
                <Styled.InputWrapper>
                    <InputLabel>Config Name</InputLabel>
                    <Select
                        fullWidth
                        id="configId"
                        name="configId"
                        value={jobData?.configId}
                        onChange={handleJobChange}
                        error={jobError?.configId} >
                        {configurationList && configurationList.length > 0 && configurationList.map((x, i) => {
                            return (<MenuItem key={i} value={x.id}  >
                                {x.connectorType}  | {x.configName}
                            </MenuItem>)
                        })}
                    </Select>
                </Styled.InputWrapper>
            </Grid>
            <Grid item lg={12} md={12} sm={12} xs={12}>
                <Styled.InputWrapper>
                    <InputLabel>Connecter Name</InputLabel>
                    <Select
                        fullWidth
                        name="connectorId"
                        value={jobData?.connectorId}
                        onChange={handleJobChange}
                        error={jobError?.connectorId}>
                        {connectorList && connectorList.length > 0 && connectorList.map((x, i) => {
                            return (<MenuItem key={i} value={x.id} onChange={jobData?.connectorId} >
                                {x.connectorType} | {x.connectorName}
                            </MenuItem>)
                        })}
                    </Select>
                </Styled.InputWrapper>
            </Grid>
            <Grid item lg={12} md={12} sm={12} xs={12}>
                <Styled.InputWrapper>
                    <InputLabel>Job Name</InputLabel>
                    <TextField
                        fullWidth
                        name="jobName"
                        value={jobData?.jobName}
                        onChange={handleJobChange}
                        error={jobError?.jobName}
                        helperText={jobError?.jobName ? `Required.` : ''} />
                </Styled.InputWrapper>
            </Grid>
            {SelectedButtonOption === CHOOSE_EXISTING_ONE ? (
                <Grid item lg={12} md={12} sm={12} xs={12} >
                    <Styled.InputWrapper>
                        <InputLabel>Job Status</InputLabel>
                        <Select
                            fullWidth name="jobStatus"
                            value={jobData?.jobStatus}
                            onChange={handleJobChange}
                            error={jobError?.jobStatus}
                            helperText={jobError?.jobStatus ? `Required.` : ''} >
                            {JOB_STATUS_LIST.map((x, i) =>
                                <MenuItem key={i}
                                    value={x.value}>
                                    {x.label}
                                </MenuItem>)}
                        </Select>
                    </Styled.InputWrapper>
                </Grid>) : ''}
            <Grid item lg={12} md={12} sm={12} xs={12} >
                <Styled.InputWrapper>
                    <InputLabel>Job Type</InputLabel>
                    <Select fullWidth name="jobType" value={jobData?.jobType} onChange={handleJobChange} error={jobError?.jobType}>
                        {JOB_TYPE_LIST.map((x, i) => <MenuItem key={i} value={x.value}>{x.label}</MenuItem>)}
                    </Select>
                </Styled.InputWrapper>
            </Grid>
            <Grid item lg={12} md={12} sm={12} xs={12}>
                <Styled.InputWrapper>
                    <InputLabel>Job Action</InputLabel>
                    <RadioGroup row name="jobActiveInactive" id="jobActiveInactive" value={jobData?.jobActiveInactive} onChange={handleJobChange} error={jobError?.jobActiveInactive} >
                        <FormControlLabel value="Enable" control={<Radio />} label="Enable" onChange={handleJobChange} />
                        <FormControlLabel value="Disable" control={<Radio />} label="Disable" onChange={handleJobChange} />
                    </RadioGroup>
                </Styled.InputWrapper>
            </Grid>
            {SelectedButtonOption === CREATE_NEW_ONE ? (
                <Grid item lg={12} md={12} sm={12} xs={12}>
                    <Styled.InputWrapper>
                        <InputLabel>Notebook Name</InputLabel>
                        <TextField
                            id="outlined-read-only-input"
                            value={notebookName}
                            onChange={handleJobChange}
                            InputProps={{
                                readOnly: true,
                            }}
                        />
                        <FormHelperText>Upload ipynb file</FormHelperText>
                    </Styled.InputWrapper>
                </Grid>) : ''}
        </Grid >
    );
};

export default JobComponent;