import { Box, Button, Drawer } from '@mui/material';
import { styled } from '@mui/system';

const MobileHeaderWrapper = styled(Box)({
    width: '70px',
    height: '70px',
});
const InsightWrapper = styled(Box)({
    MarginTop: "10px",
})

const ActionButton = styled(Button)({
    color: '#fff',
    variant: 'container',
    size: 'small',
    margin: "0px 10px"
});

const MenuItemsWrapper = styled(Box)({
    margin: '30px',
});


const StatusChangeIcon = styled(Button)({
    color: '#205878',

});

export default {
    MobileHeaderWrapper,
    ActionButton, StatusChangeIcon,
    MenuItemsWrapper,
    InsightWrapper,
};