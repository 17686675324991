import { combineReducers } from "redux";
import integrationReducer from "./integrationReducer";
import intelligenceReducer from "./intelligenceReducer";
import insightReducer from "./insightReducer";
import keplerGlReducer from 'kepler.gl/reducers';
import igniteReducer from "./igniteReducer";

function appReducer() {
    return {}
}
const keplerReducer = keplerGlReducer.initialState({
    uiState: {
        activeSidePanel: null,
        currentModal: null
    }
});

export default combineReducers({
    ignition: igniteReducer,
    integrationReducer,
    insight: insightReducer,
    intelligenceReducer,
    keplerGl: keplerReducer,
    app: appReducer
})