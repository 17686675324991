import React, { useEffect, useState } from 'react'
import { Chart } from "react-google-charts";

const GeoChart = () => {
    const [data, setData] = useState({
        value: [],
    });

    useEffect(() => {
        fetch('/chart_data.json')
            .then(response => response.json())
            .then((jsonData) => {
                const chartData = jsonData.geochart
                let values = []
                chartData.data.map((data) => {
                    values.push(data)
                })
                setData({ ...data, value: values });
            })
            .catch((error) => {
                console.error("Unabled to fetch json")
            })
    }, []);
    return (
        <>
            <Chart
                chartEvents={[
                    {
                        eventName: "select",
                        callback: ({ chartWrapper }) => {
                            const chart = chartWrapper.getChart();
                            const selection = chart.getSelection();
                            if (selection.length === 0) return;
                            const region = data[selection[0].row + 1];
                        },
                    },
                ]}
                chartType="GeoChart"
                data={data.value}
            />
        </>
    )
}

export default GeoChart