import React from 'react';
import MUIDataTable from "mui-datatables";

const IgnitionComponent = (props) => {
    const { columns, data, options, } = props;
    return (
        <>
            <MUIDataTable
                data={data}
                columns={columns}
                options={options}
            />
        </>
    )
}

export default IgnitionComponent;