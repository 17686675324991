import React from 'react';
import KeplerGl from 'kepler.gl';
import { CLOUD_PROVIDERS } from '../cloud-provider';

const KeplerMap = (props) => {
    return (
        <KeplerGl
            id="map"
            mapboxApiAccessToken={"pk.eyJ1IjoiYWJoaWJhbGFuaSIsImEiOiJja2NyZnRzM3gwaml4MzFteDU1ZnM1dGhpIn0.t8SA-yrv7Im74S8pjd5TiQ"}
            appName={'Galileosense'}
            cloudProviders={CLOUD_PROVIDERS}
        />
    )
}

export default KeplerMap    