import React, { useEffect, useState } from 'react'
import { Chart } from "react-google-charts";
const GooglePieChart = () => {
    const [data, setData] = useState({
        value: [],
    });

    useEffect(() => {
        fetch('/chart_data.json')
            .then(response => response.json())
            .then((jsonData) => {
                const chartData = jsonData.pie
                let values = []
                chartData.data.map((data) => {
                    values.push(data)
                })
                setData({ ...data, value: values });
            })
            .catch((error) => {
                console.error("Unabled to fetch json")
            })
    }, []);

    return (
        <>
            <Chart
                chartType="PieChart"
                data={data?.value}
                width="100%"
                height="100%"
                legendToggle
            />
        </>
    )
}

export default GooglePieChart