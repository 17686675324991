import React from 'react';
import { Box, Button, Checkbox, FormControl, FormControlLabel, FormGroup, FormHelperText, Grid, IconButton, InputAdornment, InputLabel, MenuItem, Select, TextField, Typography } from '@mui/material';
import { CHOOSE_EXISTING_ONE, CREATE_NEW_ONE, PLACER_AI, MAXAR, PREDICT_HQ, GCS, JOB_STATUS_LIST, JOB_TYPE_LIST } from '../../Constant';
import Styled from '../CommonStyle';
import { Marker, StaticGoogleMap } from 'react-static-google-map';
import AddIcon from '@mui/icons-material/Add';
import DoneIcon from '@mui/icons-material/Done';
import EditIcon from '@mui/icons-material/Edit';
import CheckCircleOutlineIcon from '@mui/icons-material/CheckCircleOutline';
import CancelOutlinedIcon from '@mui/icons-material/CancelOutlined';
import StyledIN from '../../Containers/IntegrationContainer/style';

const JobDetailComponent = (props) => {
    const {
        connectorTabOption,
        handleConfigChange,
        jobDetailDataError,
        jobDetailData,
        getSingleConfig,
        connectorList,
        configError,
        configData,
        connectorDetailArr,
        matches,
        handleJobCreateNew,
        jobDetailTabOption,
        handleJobChooseExisting,
        selectBucketName,
        handleJobDetailChange,
        tempBucketNameList,
        jobDestinationError,
        handleJobDestinationChange,
        jobDestination,
        jobList,
        workFlowList,
        workFlowId,
        workFlowUpdated,
        workflowNameChange,
        updateWorkflowName,
        openWorkflowEdit,
        handleWorkUpdate,
        closeWorkflowEdit,
        createWorkflowName,
        workflowNameUpdate,
        openWorkflowEdits,
        handleSidePanelDrawer,
    } = props;

    return (
        <>
            <StyledIN.ButtonWrapper>
                <Grid container>
                    <Grid style={{ display: 'flex', alignItems: 'center', }} item xs={3} sm={3} md={4}>
                    </Grid>
                    <Grid style={{ display: 'flex', alignItems: 'center', justifyContent: !matches ? 'center' : 'flex-start', }} item xs={matches ? 9 : 12} sm={matches ? 9 : 12} md={matches ? 8 : 12}>
                        <StyledIN.ButtonGroupWrapper>
                            <Button className={jobDetailTabOption === CREATE_NEW_ONE ? 'active' : ''} onClick={handleJobCreateNew}>Create New One</Button>
                            <Button className={jobDetailTabOption === CHOOSE_EXISTING_ONE ? 'active' : ''} onClick={handleJobChooseExisting}>Choose Existing One</Button>
                        </StyledIN.ButtonGroupWrapper>
                    </Grid>
                </Grid>
            </StyledIN.ButtonWrapper>
            <Grid container style={{ padding: '15px' }} spacing={2}>
                <Grid item xs={12}>
                    <Styled.InputWrapper>
                        <Typography>Job Detail Form</Typography>
                    </Styled.InputWrapper>
                </Grid>
                {connectorTabOption === CHOOSE_EXISTING_ONE && (
                    <>
                        <Grid item lg={4} md={4} sm={6} xs={12}>
                            <Styled.InputWrapper>
                                <InputLabel>Job Name</InputLabel>
                                <TextField
                                    select
                                    fullWidth
                                    name="name"
                                    value={jobDetailData?.name}
                                    onChange={handleJobDetailChange}
                                    error={jobDetailDataError?.name}
                                    helperText={jobDetailDataError?.name ? `Required.` : ''}
                                >
                                    {jobList && Object.keys(jobList).length > 0 && jobList?.message?.map((x, i) => {
                                        return <MenuItem
                                            key={i}
                                            value={x.name}
                                            onClick={() => getSingleConfig(x)}>
                                            {x.name}
                                        </MenuItem>
                                    })}
                                </TextField>
                            </Styled.InputWrapper>
                        </Grid>
                        <Grid item lg={4} md={4} sm={6} xs={12}>
                            <Styled.InputWrapper>
                                <InputLabel>Source Detail Name</InputLabel>
                                <TextField
                                    fullWidth
                                    name="sourceBucketName"
                                    disabled
                                    value={jobDetailData?.sourceBucketName ? jobDetailData?.sourceBucketName?.name : jobDetailData?.sourceBucketName}
                                    onChange={handleJobDetailChange}
                                />
                            </Styled.InputWrapper>
                        </Grid>
                        <Grid item lg={4} md={4} sm={6} xs={12}>
                            <Styled.InputWrapper>
                                <InputLabel>Destination Detail Name</InputLabel>
                                <TextField
                                    fullWidth
                                    name="destinationBucketName"
                                    disabled
                                    value={jobDetailData?.destinationBucketName ? jobDetailData?.destinationBucketName?.name : jobDetailData?.destinationBucketName}
                                    onChange={handleJobDetailChange}
                                />
                            </Styled.InputWrapper>
                        </Grid>
                        {openWorkflowEdits ? (<>
                            <Grid item lg={4} md={4} sm={6} xs={12}>
                                <Styled.InputWrapper>
                                    <InputLabel>Workflow Name</InputLabel>
                                    <TextField
                                        fullWidth
                                        name="workflowName"
                                        value={workflowNameChange}
                                        InputProps={{
                                            endAdornment: (
                                                <InputAdornment position="end">
                                                    <IconButton
                                                        aria-lbel="toggle workflow create"
                                                        onClick={closeWorkflowEdit}
                                                    >
                                                        <CancelOutlinedIcon />
                                                    </IconButton>
                                                    <IconButton
                                                        aria-lbel="toggle workflow create"
                                                        onClick={updateWorkflowName}
                                                    >
                                                        <CheckCircleOutlineIcon />
                                                    </IconButton>
                                                </InputAdornment>
                                            )
                                        }}
                                        onChange={workflowNameUpdate}
                                    />
                                </Styled.InputWrapper>
                            </Grid>
                        </>) : (
                            <>
                                <Grid item lg={4} md={4} sm={6} xs={12}>
                                    <Styled.InputWrapper>
                                        <InputLabel>Workflow Name</InputLabel>
                                        <TextField
                                            fullWidth
                                            name="workflowName"
                                            disabled
                                            value={(workFlowUpdated ? workFlowUpdated : (jobDetailData?.workflowName?.name ? jobDetailData?.workflowName?.name : jobDetailData?.workflowName))}
                                            InputProps={{
                                                endAdornment: (
                                                    <InputAdornment position="end">
                                                        <IconButton
                                                            aria-lbel="toggle workflow create"
                                                            onClick={() => openWorkflowEdit(workFlowUpdated ? workFlowUpdated : jobDetailData?.workflowName?.name)}
                                                        >
                                                            <EditIcon />
                                                        </IconButton>
                                                    </InputAdornment>
                                                )
                                            }}
                                            onChange={handleJobDetailChange}
                                        />
                                    </Styled.InputWrapper>
                                </Grid>
                            </>)
                        }
                    </>
                )
                }
                {
                    connectorTabOption === CREATE_NEW_ONE && (
                        <>
                            <Grid item lg={4} md={4} sm={6} xs={12}>
                                <Styled.InputWrapper>
                                    <InputLabel>Job Name</InputLabel>
                                    <TextField
                                        fullWidth
                                        name="name"
                                        value={jobDetailData?.name}
                                        onChange={handleJobDetailChange}
                                        error={jobDetailDataError?.name}
                                        helperText={jobDetailDataError?.name ? `Required.` : ''}
                                    />
                                </Styled.InputWrapper>
                            </Grid>
                            <Grid item lg={4} md={4} sm={6} xs={12}>
                                <Styled.InputWrapper>
                                    <InputLabel>Source Detail Name</InputLabel>
                                    <TextField
                                        id="outlined-select-currency"
                                        select
                                        fullWidth
                                        name="sourceBucketName"
                                        value={jobDetailData?.sourceBucketName?.connectorDetailId}
                                        onChange={handleJobDetailChange}
                                        error={jobDetailDataError?.sourceBucketName}
                                        helperText={jobDetailDataError?.sourceBucketName ? `Required.` : ''}
                                    >
                                        {connectorList && Object.keys(connectorList).length > 0 && connectorList?.message?.map((x, i) => {
                                            return <MenuItem
                                                key={i}
                                                value={x}
                                                onClick={() => selectBucketName(x, i)}
                                            >
                                                {x.name}
                                            </MenuItem>
                                        })}
                                    </TextField>
                                </Styled.InputWrapper>
                            </Grid>
                            <Grid item lg={4} md={4} sm={6} xs={12}>
                                <Styled.InputWrapper>
                                    <InputLabel>Destination Detail Name</InputLabel>
                                    <TextField
                                        id="outlined-select-currency"
                                        select
                                        fullWidth
                                        name="destinationBucketName"
                                        value={jobDetailData?.destinationBucketName?.connectorDetailId}
                                        onChange={handleJobDetailChange}
                                        error={jobDetailDataError?.destinationBucketName}
                                        helperText={jobDetailDataError?.destinationBucketName ? `Required.` : ''}
                                    >
                                        {connectorList && Object.keys(connectorList).length > 0 && tempBucketNameList?.map((x, i) => {
                                            return <MenuItem
                                                key={i}
                                                value={x}
                                            >
                                                {x.name}
                                            </MenuItem>
                                        })}
                                    </TextField>
                                </Styled.InputWrapper>
                            </Grid>
                            <Grid item lg={4} md={4} sm={6} xs={12}>
                                <Styled.InputWrapper>
                                    <InputLabel>Workflow Name</InputLabel>
                                    <TextField
                                        id="outlined-select-currency"
                                        // select
                                        fullWidth
                                        name="workflowName"
                                        value={jobDetailData?.workflowName}
                                        onChange={handleJobDetailChange}
                                        InputProps={{
                                            endAdornment: (
                                                <InputAdornment position="end">
                                                    {workFlowId?.workflowId && jobDetailData?.workflowName ? <DoneIcon /> : (
                                                        <IconButton
                                                            aria-lbel="toggle workflow create"
                                                            onClick={createWorkflowName}
                                                        >
                                                            <AddIcon />
                                                        </IconButton>
                                                    )}
                                                </InputAdornment>
                                            )
                                        }}
                                        error={jobDetailDataError?.workflowName}
                                        helperText={jobDetailDataError?.workflowName ? `Required.` : ''}
                                    />
                                    {!workFlowId?.workflowId ? <FormHelperText style={{ color: "#2c6d93" }}> Please click on add button to create workflow! </FormHelperText > : ``}
                                </Styled.InputWrapper>
                            </Grid>

                        </>
                    )
                }
                <Grid item lg={4} md={4} sm={6} xs={12}>
                    <Styled.InputWrapper>
                        <InputLabel>Job Type</InputLabel>
                        <TextField
                            select
                            fullWidth
                            name="type"
                            value={jobDetailData?.type}
                            onChange={handleJobDetailChange}
                            error={jobDetailDataError?.type}
                            helperText={jobDetailDataError?.type ? `Required.` : ''}
                        >
                            {JOB_TYPE_LIST && JOB_TYPE_LIST?.map((x, i) => {
                                return <MenuItem
                                    key={i}
                                    value={x.value}
                                >
                                    {x.label}
                                </MenuItem>
                            })}
                        </TextField>
                    </Styled.InputWrapper>
                </Grid>
                <Grid item lg={4} md={4} sm={6} xs={12}>
                    <Styled.InputWrapper>
                        <InputLabel>Chain Sequence Number</InputLabel>
                        <TextField
                            fullWidth
                            name="chainSeqNo"
                            type="number"
                            value={jobDetailData?.chainSeqNo}
                            onChange={handleJobDetailChange}
                            error={jobDetailDataError?.chainSeqNo}
                            helperText={jobDetailDataError?.chainSeqNo ? `Required.` : ''}
                        />
                    </Styled.InputWrapper>
                </Grid>

                {
                    jobDetailData?.sourceBucketName?.connectorType?.name === "Google Static API" && (
                        <>
                            <Grid item lg={12} md={12} sm={12} xs={12}>
                                <Styled.InputWrapper>
                                    <InputLabel>Source Detail</InputLabel>
                                </Styled.InputWrapper>
                            </Grid>
                            <Grid item lg={4} md={4} sm={6} xs={12}>
                                <Styled.InputWrapper>
                                    <InputLabel>Center</InputLabel>
                                    <TextField
                                        fullWidth
                                        name="center"
                                        value={configData?.center}
                                        onChange={handleConfigChange}
                                        error={configError?.center}
                                        helperText={configError?.center ? `Required.` : ''}
                                    />
                                </Styled.InputWrapper>
                            </Grid>
                            <Grid item lg={4} md={4} sm={6} xs={12}>
                                <Styled.InputWrapper>
                                    <InputLabel>Zoom</InputLabel>
                                    <TextField
                                        fullWidth
                                        name="zoom"
                                        type="number"
                                        value={configData?.zoom}
                                        onChange={handleConfigChange}
                                        error={configError?.zoom}
                                        helperText={configError?.zoom ? `Required.` : ''} />
                                </Styled.InputWrapper>
                            </Grid>
                            <Grid item lg={4} md={4} sm={6} xs={12}>
                                <Styled.InputWrapper>
                                    <InputLabel>Size</InputLabel>
                                    <FormControl fullWidth>
                                        <Select
                                            value={configData?.size}
                                            name="size"
                                            onChange={handleConfigChange}
                                        >
                                            <MenuItem value="200x200">200x200</MenuItem>
                                            <MenuItem value="300x300">300x300</MenuItem>
                                            <MenuItem value="400x400">400x400</MenuItem>
                                            <MenuItem value="450x450">450x450</MenuItem>
                                            <MenuItem value="500x500">500x500</MenuItem>
                                        </Select>
                                    </FormControl>
                                </Styled.InputWrapper>
                            </Grid>
                            <Grid item lg={4} md={4} sm={6} xs={12}>
                                <Styled.InputWrapper>
                                    <InputLabel>Scale</InputLabel>
                                    <TextField
                                        fullWidth
                                        name="scale"
                                        type="number"
                                        value={configData?.scale}
                                        onChange={handleConfigChange}
                                        error={configError?.scale}
                                        helperText={configError?.scale ? `Required.` : ''}
                                    />
                                </Styled.InputWrapper>
                            </Grid>
                            <Grid item lg={4} md={4} sm={6} xs={12}>
                                <Styled.InputWrapper>
                                    <InputLabel>Format</InputLabel>
                                    <FormControl fullWidth>
                                        <Select
                                            value={configData?.format}
                                            name="format"
                                            onChange={handleConfigChange}
                                        >
                                            <MenuItem value="jpg">JPG</MenuItem>
                                            <MenuItem value="png">PNG</MenuItem>
                                        </Select>
                                    </FormControl>
                                </Styled.InputWrapper>
                            </Grid>
                            <Grid item lg={4} md={4} sm={6} xs={12}>
                                <Styled.InputWrapper>
                                    <InputLabel>Map Type</InputLabel>
                                    <FormControl fullWidth>
                                        <Select
                                            value={configData?.maptype}
                                            name="maptype"
                                            onChange={handleConfigChange}
                                        >
                                            <MenuItem value="hybrid">Hybrid</MenuItem>
                                            <MenuItem value="roadmap">Roadmap</MenuItem>
                                            <MenuItem value="satellite">Satellite</MenuItem>
                                            <MenuItem value="terrain">Terrain</MenuItem>
                                        </Select>
                                    </FormControl>
                                </Styled.InputWrapper>
                            </Grid>
                            <Grid item lg={12} md={12} sm={12} xs={12}>
                                <Styled.InputWrapper>
                                    <StaticGoogleMap
                                        apiKey={process.env.REACT_APP_GOOGLE_STATIC_MAP_API_KEY}
                                        center={configData?.center ? configData?.center : null}
                                        zoom={configData?.zoom ? configData?.zoom : null}
                                        size={configData?.size ? configData?.size : "400x400"}
                                        scale={configData?.scale ? configData?.scale : null}
                                        format={configData?.format ? configData?.format : null}
                                        maptype={configData?.maptype ? configData?.maptype : null}
                                    >
                                        <Marker color="blue" label="S" location={[11211, 11206, 11222]} />
                                        {/*  <Path.Group color="0x0000ff" weight="5" fillcolor="0xFFFF0033">
                                        <Path points="40.737102,-73.990318" />
                                        <Path points="40.749825,-73.987963" />
                                        <Path points="40.752946,-73.987384" />
                                        <Path points="40.755823,-73.986397" />
                                    </Path.Group> */}
                                    </StaticGoogleMap>
                                </Styled.InputWrapper>
                            </Grid>
                        </>
                    )
                }
                {
                    jobDetailData?.sourceBucketName?.connectorType?.name === "Google Place Detail API" && (
                        <>
                            <Grid item lg={12} md={12} sm={12} xs={12}>
                                <Styled.InputWrapper>
                                    <InputLabel>Source Detail</InputLabel>
                                </Styled.InputWrapper>
                            </Grid>
                            <Grid item lg={4} md={4} sm={6} xs={12}>
                                <Styled.InputWrapper>
                                    <InputLabel>Address</InputLabel>
                                    <TextField
                                        fullWidth
                                        name="address"
                                        value={configData?.address}
                                        onChange={handleConfigChange}
                                        error={configError?.address}
                                        helperText={configError?.address ? `Required.` : ''}
                                    />
                                </Styled.InputWrapper>
                            </Grid>
                            <Grid item lg={6} md={6} sm={12} xs={12}>
                                <Grid item lg={12} md={12} sm={12} xs={12} style={{ textAlign: "left" }}>
                                    <InputLabel>Do you want to access?</InputLabel>
                                </Grid>
                                <Grid item lg={12} md={12} sm={12} xs={12}>
                                    <Box style={{ display: "flex", marginTop: "11px" }}>
                                        <Styled.InputWrapper>
                                            <FormGroup>
                                                <FormControlLabel name="contactInfo" onChange={handleConfigChange} control={<Checkbox checked={configData?.contactInfo} />} label="Contact Information" />
                                            </FormGroup>
                                        </Styled.InputWrapper>
                                        <Styled.InputWrapper>
                                            <FormGroup>
                                                <FormControlLabel name="review" onChange={handleConfigChange} control={<Checkbox checked={configData?.review} />} label="Review" />
                                            </FormGroup>
                                        </Styled.InputWrapper>
                                    </Box>
                                </Grid>
                            </Grid>
                        </>
                    )
                }
                {
                    jobDetailData?.destinationBucketName?.connectorType?.name === "AWS-S3" && (
                        <>
                            <Grid item lg={12} md={12} sm={12} xs={12}>
                                <Styled.InputWrapper>
                                    <InputLabel>Destination Detail</InputLabel>
                                </Styled.InputWrapper>
                            </Grid>
                            <Grid item lg={4} md={4} sm={6} xs={12}>
                                <Styled.InputWrapper>
                                    <InputLabel>Bucket Name</InputLabel>
                                    <TextField
                                        fullWidth
                                        name="bucketName"
                                        value={jobDestination?.bucketName}
                                        onChange={(e) => handleJobDestinationChange(e)}
                                        error={jobDestinationError?.bucketName}
                                        helperText={jobDestinationError?.bucketName ? `Required.` : ''} />
                                </Styled.InputWrapper>
                            </Grid>
                            <Grid item lg={4} md={4} sm={6} xs={12}>
                                <Styled.InputWrapper>
                                    <InputLabel>File Object Key Name</InputLabel>
                                    <TextField
                                        fullWidth
                                        name="fileObjKeyName"
                                        value={jobDestination?.fileObjKeyName}
                                        onChange={(e) => handleJobDestinationChange(e)}
                                        error={jobDestinationError?.fileObjKeyName}
                                        helperText={jobDestinationError?.fileObjKeyName ? `Required.` : ''} />
                                </Styled.InputWrapper>
                            </Grid>
                            <Grid item lg={4} md={4} sm={6} xs={12}>
                                <Styled.InputWrapper>
                                    <InputLabel>File Prefix</InputLabel>
                                    <TextField
                                        fullWidth
                                        name="filePrefix"
                                        value={jobDestination?.filePrefix}
                                        onChange={(e) => handleJobDestinationChange(e)}
                                        error={jobDestinationError?.filePrefix}
                                        helperText={jobDestinationError?.filePrefix ? `Required.` : ''} />
                                </Styled.InputWrapper>
                            </Grid>
                        </>
                    )
                }
                {
                    jobDetailData?.destinationBucketName?.connectorType?.name === "Google Cloud Storage" && (
                        <>
                            <Grid item lg={12} md={12} sm={12} xs={12}>
                                <Styled.InputWrapper>
                                    <InputLabel>Destination Detail</InputLabel>
                                </Styled.InputWrapper>
                            </Grid>
                            <Grid item lg={4} md={4} sm={6} xs={12}>
                                <Styled.InputWrapper>
                                    <InputLabel>Project Id</InputLabel>
                                    <TextField
                                        fullWidth
                                        name="projectId"
                                        value={jobDestination?.projectId}
                                        onChange={(e) => handleJobDestinationChange(e)}
                                        error={jobDestinationError?.projectId}
                                        helperText={jobDestinationError?.projectId ? `Required.` : ''} />
                                </Styled.InputWrapper>
                            </Grid>
                            <Grid item lg={4} md={4} sm={6} xs={12}>
                                <Styled.InputWrapper>
                                    <InputLabel>Bucket Name</InputLabel>
                                    <TextField
                                        fullWidth
                                        name="bucketName"
                                        value={jobDestination?.bucketName}
                                        onChange={(e) => handleJobDestinationChange(e)}
                                        error={jobDestinationError?.bucketName}
                                        helperText={jobDestinationError?.bucketName ? `Required.` : ''} />
                                </Styled.InputWrapper>
                            </Grid>
                            <Grid item lg={4} md={4} sm={6} xs={12}>
                                <Styled.InputWrapper>
                                    <InputLabel>File Object Key Name</InputLabel>
                                    <TextField
                                        fullWidth
                                        name="fileObjKeyName"
                                        value={jobDestination?.fileObjKeyName}
                                        onChange={(e) => handleJobDestinationChange(e)}
                                        error={jobDestinationError?.fileObjKeyName}
                                        helperText={jobDestinationError?.fileObjKeyName ? `Required.` : ''} />
                                </Styled.InputWrapper>
                            </Grid>
                            <Grid item lg={4} md={4} sm={6} xs={12}>
                                <Styled.InputWrapper>
                                    <InputLabel>File Prefix</InputLabel>
                                    <TextField
                                        fullWidth
                                        name="filePrefix"
                                        value={jobDestination?.filePrefix}
                                        onChange={(e) => handleJobDestinationChange(e)}
                                        error={jobDestinationError?.filePrefix}
                                        helperText={jobDestinationError?.filePrefix ? `Required.` : ''} />
                                </Styled.InputWrapper>
                            </Grid>
                        </>
                    )
                }


            </Grid >
        </>
    )
}

export default JobDetailComponent

