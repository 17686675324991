import {
    //JOB
    GET_JOB_LIST, POST_JOB_DATA_RECORD, GET_SINGLE_JOB_RECORD, DELETE_SINGLE_JOB_RECORD,

    //SNACK
    SNACKBAR_CONFIG,
    SNACKBAR_WORKFLOW,
    UPDATE_JOB_STATUS_RUN,
} from "../Constant";

const initialState = {
    jobList: [],
    snackbar: {
        open: false,
        severity: '',
        message: '',
    }
};

const intelligenceReducer = (state = initialState, action) => {

    switch (action.type) {

        case SNACKBAR_CONFIG:
            return {
                ...state,
                snackbar: action.payload,
            }

        case GET_JOB_LIST:
            return {
                ...state,
                jobList: action.payload,
            };

        case POST_JOB_DATA_RECORD:
            return {
                ...state,
                jobList: [...state.jobList, { ...action.payload }],
            };

        case GET_SINGLE_JOB_RECORD:
            return {
                ...state,
                singleJob: action.payload,
            };

        case DELETE_SINGLE_JOB_RECORD:
            let tempData = { ...state };
            return {
                ...state,
                jobList: tempData.jobList.filter(e => e.jobId !== action.payload)
            }

        default:
            return state;
    }
}

export default intelligenceReducer;