// ACTIONS

//CONNECTOR
export const REQUEST_CONNECTOR_LIST = "REQUEST_CONNECTOR_LIST";
export const REQUEST_CONNECTOR_TYPE = "REQUEST_CONNECTOR_TYPE"
export const GET_CONNECTOR_TYPE = "GET_CONNECTOR_TYPE"
export const REQUEST_UPDATE_CONNECTOR = "REQUEST_UPDATE_CONNECTOR";
export const GET_CONNECTOR_LIST = "GET_CONNECTOR_LIST";
export const GOOGLE_STATIC_API = "Google Static API"
export const UPDATE_SINGLE_CONNECTOR_RECORD = "UPDATE_SINGLE_CONNECTOR_RECORD";
export const REQUEST_CREATE_CONNECTOR = "REQUEST_CREATE_CONNECTOR";
export const REQUEST_DELETE_CONNECTOR = "REQUEST_DELETE_CONNECTOR";
export const POST_CONNECTOR_DATA = "POST_CONNECTOR_DATA";
export const REQUEST_SINGLE_CONNECTOR = "REQUEST_SINGLE_CONNECTOR";
export const GET_SINGLE_CONNECTOR_DATA = "GET_SINGLE_CONNECTOR_DATA";
export const DELETE_CONNECTOR_RECORD = "DELETE_CONNECTOR_RECORD";
export const SELECT_CONNECTOR_TAB_STATUS = "SELECT_CONNECTOR_TAB_STATUS"
export const SELECT_JOB_TAB_STATUS = "SELECT_JOB_TAB_STATUS"

//CONFIG
export const REQUEST_CONFIG_LIST = "REQUEST_CONFIG_LIST";
export const REQUEST_CREATE_JOB_DETAIL = "REQUEST_CREATE_JOB_DETAIL";
export const REQUEST_SINGLE_CONFIG = "REQUEST_SINGLE_CONFIG";
export const REQUEST_UPDATE_CONFIG = "REQUEST_UPDATE_CONFIG";
export const REQUEST_DELETE_CONFIG = "REQUEST_DELETE_CONFIG";
export const GET_CONFIG_LIST = "GET_CONFIG_LIST";
export const GET_SINGLE_CONFIG_DATA = "GET_SINGLE_CONFIG_DATA";
export const POST_CONFIG_DATA = "POST_CONFIG_DATA";
export const UPDATE_SINGLE_CONFIG_RECORD = "UPDATE_SINGLE_CONFIG_RECORD";
export const DELETE_CONFIGURATION_RECORD = "DELETE_CONFIGURATION_RECORD";

//SNACKBAR
export const SNACKBAR_CONFIG = "SNACKBAR_CONFIG";
export const REQUEST_SNACKBAR_CONFIG = "REQUEST_SNACKBAR_CONFIG";
export const SNACKBAR_WORKFLOW = "SNACKBAR_WORKFLOW";

//JOb new
export const REQUEST_SINGLE_JOB_DETAIL = "REQUEST_SINGLE_JOB_DETAIL"
export const GET_SINGLE_JOB_DETAIL = "GET_SINGLE_JOB_DETAIL"
export const REQUEST_UPDATE_JOB_DETAIL = "REQUEST_UPDATE_JOB_DETAIL"
export const UPDATE_SINGLE_JOB_DETAIL_RECORD = "UPDATE_SINGLE_JOB_DETAIL_RECORD"
export const REQUEST_DELETE_JOB_DETAIL = "REQUEST_DELETE_JOB_DETAIL"
export const DELETE_JOB_DETAIL_RECORD = "DELETE_JOB_DETAIL_RECORD"
//JOB
export const REQUEST_JOB_LIST = "REQUEST_JOB_LIST"; //REQUEST TO GET ALL JOBLIST
export const REQUEST_CREATE_JOB = "REQUEST_CREATE_JOB"; //REQUEST TO CREATE A JOB
export const REQUEST_SINGLE_JOB = "REQUEST_SINGLE_JOB"; //REQUEST TO GET 1 JOB
export const REQUEST_UPDATE_JOB = "REQUEST_UPDATE_JOB"; //REQUEST TO UPDATE  A JOB
export const REQUEST_DELETE_JOB = "REQUEST_DELETE_JOB"; //REQUEST TO DELETE A JOB
export const GET_JOB_LIST = "GET_JOB_LIST"; //GET ALL JOBLIST
export const GET_SINGLE_JOB_RECORD = "GET_SINGLE_JOB_RECORD"; //GET DATA OF 1 JOB
export const POST_JOB_DATA_RECORD = "POST_JOB_DATA_RECORD"; //SEND DATA OF JOB
export const UPDATE_SINGLE_JOB_RECORD = "UPDATE_SINGLE_JOB_RECORD"; //TO UPDATE SINGLE RECORD
export const DELETE_SINGLE_JOB_RECORD = "DELETE_SINGLE_JOB_RECORD"; //DELETE A JOB
export const POST_NOTEBOOK_DETAILS = "POST_NOTEBOOK_DETAILS";
export const REQUEST_JOB_DETAILS = "REQUEST_JOB_DETAILS";
export const GET_JOB_DETAILS = "GET_JOB_DETAILS";

export const DISPLAY_SNACKBAR = "DISPLAY_SNACKBAR"
//Widget
export const REQUEST_WIDGET_DETAILS = "REQUEST_WIDGET_DETAILS"
export const GET_WIDGET_DETAILS = "GET_WIDGET_DETAILS"
export const SAVE_WIDGET_DETAILS = "SAVE_WIDGET_DETAILS"
export const DELETE_WIDGET_ARRAY_DETAILS = "DELETE_WIDGET_ARRAY_DETAILS"

//WORK FLOW
export const REQUEST_WORKFLOW_TYPE = "REQUEST_WORKFLOW_TYPE"
export const GET_WORKFLOW_TYPE = "GET_WORKFLOW_TYPE"
export const REQUEST_CREATE_WORKFLOW = "REQUEST_CREATE_WORKFLOW"
export const POST_WORKFLOW_DATA = "POST_WORKFLOW_DATA"
export const REMOVE_WORKFLOW_NAME = "REMOVE_WORKFLOW_NAME"
export const WORKFLOW_NOT_EXIST = "WORKFLOW_NOT_EXIST"
export const UPDATE_WORKFLOW_NAME = "UPDATE_WORKFLOW_NAME"
export const UPDATED_WORKFLOW_NAME_RECORD = "UPDATED_WORKFLOW_NAME_RECORD"
export const REMOVE_UPDATED_WORKFLOW_NAME = "REMOVE_UPDATED_WORKFLOW_NAME"
export const REQUEST_WORKFLOW_NAME = "REQUEST_WORKFLOW_NAME"
export const GET_WORKFLOW_NAME = "GET_WORKFLOW_NAME"

//JOB STATUS
export const REQUEST_UPDATE_JOB_STATUS_ABORT = "REQUEST_UPDATE_JOB_STATUS_ABORT";
export const REQUEST_UPDATE_JOB_STATUS_PAUSE = "REQUEST_UPDATE_JOB_STATUS_PAUSE";
export const START_JOB_STATUS_LOADER = "START_JOB_STATUS_LOADER";
export const REQUEST_UPDATE_JOB_STATUS_STOP = "REQUEST_UPDATE_JOB_STATUS_STOP";
export const REQUEST_UPDATE_JOB_STATUS_RESUME = "REQUEST_UPDATE_JOB_STATUS_RESUME";
export const UPDATE_JOB_STATUS_ABORT = "UPDATE_JOB_STATUS_ABORT";
export const UPDATE_JOB_STATUS_PAUSE = "UPDATE_JOB_STATUS_PAUSE";
export const UPDATE_JOB_STATUS_STOP = "UPDATE_JOB_STATUS_STOP";
export const UPDATE_JOB_STATUS_RUN = "UPDATE_JOB_STATUS_RUN";
export const REQUEST_UPDATE_JOB_STATUS_RUN = "REQUEST_UPDATE_JOB_STATUS_RUN";

// COMPARISION_STRINGS
export const CREATE_NEW_ONE = "Create New One";
export const CHOOSE_EXISTING_ONE = "Choose Existing One";
export const CONNECTOR = "Connector";
export const CONFIG = "Config";
export const S3 = "S3";
export const PREDICT_HQ = "PREDICT_HQ";
export const GCS = "GCS";
export const JOB = "Job";
export const PLACER_AI = "PLACER_AI"
export const GOOGLE_ANALYTICS = "Google Analytics"
export const MAXAR = "MAXAR"
export const CONNECTOR_TYPE = "connectorType"
export const GOOGLE_STATIC_MAP = "Google Static API"

export const JOB_STATUS_LIST = [
    { label: 'RUN', value: 'RUN' },
    { label: 'RE-RUN', value: 'RERUN' },
    { label: 'PAUSE', value: 'PAUSE' },
    { label: 'RESUME', value: 'RESUME' },
    { label: 'COMPLETE', value: 'COMPLETE' },
    { label: 'CANCELED', value: 'CANCELED' },
];

export const JOB_TYPE_LIST = [
    { label: 'MANUAL', value: 'MANUAL' },
    { label: 'SCHEDULE', value: 'SCHEDULE' },
    { label: 'REALTIME', value: 'REALTIME' },
];
