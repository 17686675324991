import React, { useEffect, useState } from 'react'
import { Chart } from "react-google-charts";

const TimeLineChart = () => {
    const [data, setData] = useState({ columns: [], rows: [] });
    const dataSet = [
        [
            { type: "string", id: "Term" },
            { type: "string", id: "Name" },
            { type: "date", id: "Start" },
            { type: "date", id: "End" },
        ],
        ["1", "George Washington", new Date(1789, 3, 30), new Date(1797, 2, 4)],
        ["2", "John Adams", new Date(1797, 2, 4), new Date(1801, 2, 4)],
        ["3", "Thomas Jefferson", new Date(1801, 2, 4), new Date(1809, 2, 4)],
    ];

    useEffect(() => {
        fetch('/chart_data.json')
            .then(response => response.json())
            .then((jsonData) => {
                const chartData = jsonData.timeline
                let column = []
                let row = []
                chartData.data.map((data) => {
                    column.push(data.columns)
                    data.rows.map((item) => {
                        row.push(item)
                    })
                })
                setData({ ...data, columns: column, rows: row });
            })
            .catch((error) => {
                console.error("Unabled to fetch json")
            })
    }, []);

    const dataset = [data.columns, ...data.rows];
    return (
        <>
            <Chart chartType="Timeline" data={dataSet} />
        </>
    )
}

export default TimeLineChart