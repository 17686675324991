import { MenuItem, Select } from '@mui/material';
import React, { useState } from 'react';
import CommonStyled from '../CommonStyle';

const SidePanelComponent = () => {
    return (
        <CommonStyled.Sidebar>
            <CommonStyled.DropDownWrapper />
        </CommonStyled.Sidebar>
    );
};

export default SidePanelComponent;
