import { put, call } from "redux-saga/effects";
import { axiosV1, axiosV2 } from "../axiosConfig";
import {
    GET_CONNECTOR_LIST,
    POST_CONFIG_DATA,
    POST_CONNECTOR_DATA,
    GET_CONFIG_LIST,
    GET_SINGLE_CONFIG_DATA,
    GET_SINGLE_CONNECTOR_DATA,
    UPDATE_SINGLE_CONNECTOR_RECORD,
    UPDATE_SINGLE_CONFIG_RECORD,
    SNACKBAR_CONFIG,
    DELETE_CONNECTOR_RECORD,
    DELETE_CONFIGURATION_RECORD,
    GET_CONNECTOR_TYPE,
    GET_WORKFLOW_TYPE,
    GET_JOB_DETAILS,
    UPDATE_SINGLE_JOB_DETAIL_RECORD,
    DELETE_JOB_DETAIL_RECORD,
    SNACKBAR_WORKFLOW,
    POST_WORKFLOW_DATA,
    UPDATED_WORKFLOW_NAME_RECORD,
} from "../Constant";

export function* getConnectors() {
    try {
        const { data } = yield call(axiosV2.get, '/connectorDetail?pageNo=1&pageSize=100');
        return yield put({ type: GET_CONNECTOR_LIST, payload: data });
    } catch (error) {
        yield put({ type: SNACKBAR_CONFIG, payload: { open: true, severity: 'error', message: error.message } });
    }
};

export function* getConnectorType() {
    try {
        const { data } = yield call(axiosV2.get, '/connectorType?pageNo=1&pageSize=100')
        return yield put({ type: GET_CONNECTOR_TYPE, payload: data })
    }
    catch (error) {
        yield put({ type: SNACKBAR_CONFIG, payload: { open: true, severity: 'error', message: error.message } });
    }
}

export function* getConfigs() {
    try {
        const { data } = yield call(axiosV1.get, '/config/list');
        return yield put({ type: GET_CONFIG_LIST, payload: data });
    } catch (error) {
        yield put({ type: SNACKBAR_CONFIG, payload: { open: true, severity: 'error', message: error.message } });
    }
};

export function* getWorkFlow() {
    try {
        const { data } = yield call(axiosV2.get, '/workflow')
        return yield put({ type: GET_WORKFLOW_TYPE, payload: data })
    }
    catch (error) {
        yield put({ type: SNACKBAR_CONFIG, payload: { open: true, severity: 'error', message: error.message } });
    }
}

export function* createConnector({ payload }) {
    try {
        const { data } = yield call(axiosV2.post, '/connectorDetail', payload);
        if (data) {
            yield put({ type: SNACKBAR_CONFIG, payload: { open: true, severity: 'success', message: 'Connector created successfully!' } });
        }
        return yield put({ type: POST_CONNECTOR_DATA, payload: data.message });
    } catch (error) {
        yield put({ type: SNACKBAR_CONFIG, payload: { open: true, severity: 'error', message: error.message } });
    }
};

export function* createConfig({ payload }) {
    try {
        const { data } = yield call(axiosV2.post, '/jobDetail/', payload);
        if (data) {
            yield put({ type: SNACKBAR_CONFIG, payload: { open: true, severity: 'success', message: 'Job Detail created successfully!' } });
        }
        return yield put({ type: POST_CONFIG_DATA, payload: data });
    } catch (error) {
        yield put({ type: SNACKBAR_CONFIG, payload: { open: true, severity: 'error', message: error.message } });
    }
};

export function* createWorkflow({ payload }) {
    try {
        const { data } = yield call(axiosV2.post, '/workflow', { name: payload })
        if (data) {
            yield put({ type: SNACKBAR_WORKFLOW, payload: { open: true, severity: 'success', message: 'Workflow Name created succesfully!' } })
        }
        return yield put({ type: POST_WORKFLOW_DATA, payload: data });
    }
    catch (error) {
        yield put({ type: SNACKBAR_WORKFLOW, payload: { open: true, severity: 'error', message: error.message } });
    }

}

export function* getConnector({ payload }) {
    try {
        const { data } = yield call(axiosV2.get, '/connectorDetail/' + payload);
        return yield put({ type: GET_SINGLE_CONNECTOR_DATA, payload: data });
    } catch (error) {
        yield put({ type: SNACKBAR_CONFIG, payload: { open: true, severity: 'error', message: error.message } });
    }
};

export function* getJobDetail() {
    try {
        const { data } = yield call(axiosV2.get, '/jobDetail?pageNo=1&pageSize=100');
        return yield put({ type: GET_JOB_DETAILS, payload: data });
    }
    catch (error) {
        yield put({ type: SNACKBAR_CONFIG, payload: { open: true, severity: 'error', message: error.message } });
    }
}

export function* updateConnectorRecord({ payload }) {
    let tempConnectorDetail = payload.data
    try {
        const { data } = yield call(axiosV2.put, '/connectorDetail', { connectorDetailId: payload.connectorDetailId, ...tempConnectorDetail });
        if (data) {
            yield put({ type: SNACKBAR_CONFIG, payload: { open: true, severity: 'success', message: 'Connector update successfully!' } });
        }
        return yield put({ type: UPDATE_SINGLE_CONNECTOR_RECORD, payload: data });
    } catch (error) {
        yield put({ type: SNACKBAR_CONFIG, payload: { open: true, severity: 'error', message: error.message } });
    }
};

export function* updateJobDetailRecord({ payload }) {
    try {
        const { data } = yield call(axiosV2.put, '/jobDetail', { jobId: payload.id, ...payload.data });
        if (data) {
            yield put({ type: SNACKBAR_CONFIG, payload: { open: true, severity: 'success', message: 'Job Detail updated successfully!' } });
        }
        return yield put({ type: UPDATE_SINGLE_JOB_DETAIL_RECORD, payload: data });
    } catch (error) {
        yield put({ type: SNACKBAR_CONFIG, payload: { open: true, severity: 'error', message: error.message } });
    }
};

export function* deleteConnectorRecord({ payload }) {

    try {
        yield call(axiosV2.delete, 'connectorDetail' + `?id=${payload}`);
        yield put({ type: SNACKBAR_CONFIG, payload: { open: true, severity: 'success', message: 'Connector deleted successfully!' } });
        return yield put({ type: DELETE_CONNECTOR_RECORD, payload: payload });
    } catch (error) {
        yield put({ type: SNACKBAR_CONFIG, payload: { open: true, severity: 'error', message: error.message } });
    }
};

export function* updateWorkName({ payload }) {
    try {
        const { data } = yield call(axiosV2.put, `/workflow`, payload)
        if (data) {
            yield put({ type: SNACKBAR_WORKFLOW, payload: { open: true, severity: 'success', message: 'Workflow Name Updated Successfully!' } });
        }
        return yield put({ type: UPDATED_WORKFLOW_NAME_RECORD, payload: data });
    }
    catch (error) {
        yield put({ type: SNACKBAR_WORKFLOW, payload: { open: true, severity: 'error', message: error.message } });
    }
}


export function* deleteJobDetailRecord({ payload }) {
    try {
        yield call(axiosV2.delete, '/jobDetail' + `?id=${payload}`);
        yield put({ type: SNACKBAR_CONFIG, payload: { open: true, severity: 'success', message: 'Job Detail deleted successfully!' } });
        return yield put({ type: DELETE_JOB_DETAIL_RECORD, payload: payload });
    } catch (error) {
        yield put({ type: SNACKBAR_CONFIG, payload: { open: true, severity: 'error', message: error.message } });
    }
};

export function* handleSnackBarClose() {
    try {
        return yield put({ type: SNACKBAR_CONFIG, payload: { open: false, severity: 'success', message: '' } });
    } catch (error) {
        yield put({ type: SNACKBAR_CONFIG, payload: { open: true, severity: 'error', message: error.message } });
    }
}
