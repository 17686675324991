import React, { useRef, useEffect, useState } from "react"
/* eslint import/no-webpack-loader-syntax: off */
import mapboxgl from '!mapbox-gl';
import { Box } from "@mui/material"

mapboxgl.accessToken = process.env.REACT_APP_MAPBOX_ACCESS_TOKEN

export const MapBoxGL = (props) => {
    const { dataJson } = props;
    const mapContainer = useRef()
    const [data, setData] = useState({
        layerData: null,
        map: null,
    });
    useEffect(() => {
        const chartData = JSON.parse(dataJson)
        setData({ ...data, layerData: chartData?.mapboxbuilding?.layerdata, map: chartData?.mapboxbuilding?.map });
    }, [])

    if (data?.map && data?.layerData) {
        const map = new mapboxgl.Map(data?.map);
        map.on('load', () => {
            // Insert the layer beneath any symbol layer.
            const layers = map.getStyle().layers;
            const labelLayerId = layers.find(
                (layer) => layer.type === 'symbol' && layer.layout['text-field']
            ).id;

            // The 'building' layer in the Mapbox Streets
            map.addLayer(data?.layerData, labelLayerId);
        });
    }

    return (
        <Box
            id="map"
            ref={mapContainer}
            style={{ width: "83%", height: "65vh", margin: "auto" }}
        />
    )
}

export default MapBoxGL