import styled from "@emotion/styled";
import { ButtonGroup, Drawer, Box, Button } from "@mui/material";

const CustomDrawer = styled(Drawer)({
    "& .MuiPaper-root": {
        width: "60%",
        backgroundColor: '#205878',
    },
    "& .logoSection": {
        padding: "15px",
    },
    "& .navLink": {
        fontWeight: '500',
        fontSize: '16px',
        lineHeight: '19px',
        textDecoration: 'none',
        color: '#828380'
    },
    "& .drawerHeader": {
        "& button": {
            display: 'none'
        }
    },
    "& .outerBox": {
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'center'
    },
    "& .profileWrapper": {
        display: "flex",
        justifyContent: "flex-start",
        alignItems: "center",
        marginLeft: "15px",
        "& .userDrawerImage": {
            width: "50px",
            height: "50px",
            marginRight: "15px",
            cursor: "pointer",
            objectFit: "contain"
        }
    }
});

const InputWrapper = styled(Box)({
    textAlign: 'left',
    marginBottom: '10px',
    label: {
        marginBottom: '5px',
    }
});

const SwitchWrapper = styled(Box)({
    display: 'flex',
    alignItems: 'center',
    label: {
        marginRight: '15px',
    }
});

const ButtonWrapper = styled(ButtonGroup)({
    backgroundColor: '#666666',
    border: 'none',
    padding: '0',
    borderRadius: '5px',
    button: {
        color: '#fff',
        border: 'none !important',
    },
    '.active': {
        backgroundColor: '#1f77e5',
    }
});

const NotificationWrapper = styled(Box)({
    display: 'flex',
    padding: '0',
    borderRadius: 0,
    alignItems: 'center',
    gap: '10px',
});

const MenuWrapper = styled(Box)({
    display: 'flex',
    alignItems: 'center',
    button: {
        background: '#fff',
        borderRadius: '100px',
        width: '70px',
        height: '70px',
        display: 'flex',
        alignItems: ' center',
        justifyContent: 'center',
        color: '#000',
        padding: '0',
        position: 'relative',
        "&:hover": {
            background: '#fff',
        },
    },
});

const Image = styled('img')({
    width: '100%',
    height: '100%',
    borderRadius: '100px',
});

const DropDownWrapper = styled(Box)({
    position: 'relative',
    '& fieldset': {
        borderColor: 'white',
    },
    '& .MuiOutlinedInput-root:hover fieldset': {
        borderColor: 'white',
    },
    '& .MuiOutlinedInput-root .MuiSelect-select': {
        color: 'white',
    },
    '& .MuiOutlinedInput-root svg': {
        color: 'white',
    },
    '& .MuiOutlinedInput-root.Mui-focused fieldset': {
        borderColor: 'white',
    }
});

const HeaderContentWrapper = styled(Box)({
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    padding: 8,
    borderRadius: 0,
    background: '#205878',
    color: '#fff',
    "@media (max-width: 991px)": {
        position: 'sticky',
        top: '0',
        left: '0',
        right: '0',
        zIndex: '999',
    }
});

const HeaderMenu = styled(Box)({
    position: 'relative',
    a: {
        color: '#000a3f',
        background: '#fff',
        marginLeft: '50px',
        padding: '5px 24px',
        textDecoration: 'none',
        fontSize: '14px',
        display: 'inline-block',
        "&:hover": {
            background: '#000a3f',
            color: '#fff',
        }
    },
    "@media (max-width: 991px)": {
        display: 'flex',
        flexDirection: 'column',
        gap: '20px',
        marginTop: '20px',
        a: {
            fontSize: '16px',
            color: '#fff',
            background: 'transparent',
            marginLeft: '25px',
        }
    }
});

const Sidebar = styled(Box)({
    background: '#2c6d93',
    padding: '20px',
    width: '230px',

    "@media (max-width: 991px)": {
        background: 'transparent',
    },
    "@media (max-width: 575px)": {
        width: '180px',
        maxWidth: '180px'
    },
});

const tryButtonWrapper = styled(Button)({
    background: "#2c6d93",
    width: '13vw',
    height: "7vh",
    color: "white",
    marginTop: "6vh",
    marginBottom: "2vh",
    minHeight: "60px",
    minWidth: "90px",
    textAlign: "center",
    "&:hover": {
        background: '#000a3f',
        color: '#fff',
    }
})

export default {
    CustomDrawer,
    InputWrapper,
    SwitchWrapper,
    ButtonWrapper,
    NotificationWrapper,
    MenuWrapper,
    Image,
    tryButtonWrapper,
    DropDownWrapper,
    HeaderContentWrapper,
    HeaderMenu,
    Sidebar,
}