import React, { useEffect } from 'react';
import { Amplify, Hub } from 'aws-amplify';
import awsmobile from '../../../aws-exports';
import { Authenticator } from '@aws-amplify/ui-react';
import '@aws-amplify/ui-react/styles.css';
import { Box } from '@mui/material';

export const AWS_LOGIN_URL = 'aws/aws-login';
export const AWS_WEB_CLIENT_ID = awsmobile && awsmobile.aws_cognito_identity_pool_id;
Amplify.configure({ ...awsmobile, ssr: true });

const AwsLogin = () => {

    // useEffect(() => {
    //     Hub.listen('auth', data => {
    //         const { payload } = data;
    //         if (payload.event === 'signIn') {
    //             window.opener.postMessage({ success: true }, window.location.origin);
    //         }
    //     });
    // }, []);

    return (<>
        <Box style={{ position: "fixed", top: "50%", left: "50%", transform: "translate(-50%, -50%)" }}>
            {/* <Authenticator usernameAlias="email">
            </Authenticator> */}
        </Box>
    </>
    )
};

export default AwsLogin;