import React, { useEffect, useState } from 'react'
import Plot from 'react-plotly.js'

const Table = () => {
    const [data, setData] = useState({
        value: [],
    });
    useEffect(() => {
        fetch('/chart_data.json')
            .then(response => response.json())
            .then((jsonData) => {
                const chartData = jsonData.table
                let values = []
                chartData.data.map((data) => {
                    values.push(data)
                })
                setData({ ...data, value: values });
            })
            .catch((error) => {
                console.error("Unabled to fetch json")
            })
    }, []);
    return (
        <> <Plot
            data={data.value}
        /></>
    )
}

export default Table