import { put, call } from "redux-saga/effects";
import { axiosV2 } from "../axiosConfig";
import {
    //SNACKBAR
    DISPLAY_SNACKBAR,
    UPDATE_JOB_STATUS_RUN,
    UPDATE_JOB_STATUS_ABORT,

} from "../Constant";


export function* updateJobDetailStatusRun({ payload }) {
    try {
        const { data } = yield call(axiosV2.get, '/jobDetail/run/' + payload.data)
        if (data) {
            yield put({ type: DISPLAY_SNACKBAR, payload: { open: true, severity: 'success', message: 'Job Status Updated Successfully!' } })
        }
        return yield put({ type: UPDATE_JOB_STATUS_RUN, payload: { id: payload.data, status: data.message } })
    }
    catch (error) {
        yield put({ type: DISPLAY_SNACKBAR, payload: { open: true, severity: 'error', message: error.message } });
    }
}

export function* updateJobDetailStatusAbort({ payload }) {
    try {
        const { data } = yield call(axiosV2.get, '/jobDetail/abort/' + payload.data);
        if (data) {
            yield put({ type: DISPLAY_SNACKBAR, payload: { open: true, severity: 'success', message: 'Job Status Updated Successfully!' } });
        }
        return yield put({ type: UPDATE_JOB_STATUS_ABORT, payload: { id: payload.data, status: data.message } });
    } catch (error) {
        yield put({ type: DISPLAY_SNACKBAR, payload: { open: true, severity: 'error', message: error.message } });
    }
};
