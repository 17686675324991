import React, { useState } from 'react';
import {
    Button,
} from '@mui/material';
import {
    Menu as MenuIcon,
    Notifications as NotificationsIcon,
} from '@mui/icons-material';
import { Link } from 'react-router-dom';
import Styled from './style';
import CommonStyled from '../CommonStyle';
import { Auth } from 'aws-amplify';

const HeaderComponentMobile = () => {
    const [openDrawer, setOpenDrawer] = useState(false);
    const logout = async () => {
        Auth.signOut()
    }
    return (
        <CommonStyled.HeaderContentWrapper>
            <Styled.MobileHeaderWrapper>
                <Button>
                    <CommonStyled.Image src="images/galileosense.jpg"></CommonStyled.Image>
                </Button>
            </Styled.MobileHeaderWrapper>
            <Styled.MenuItemsWrapper>
                <CommonStyled.NotificationWrapper>
                    <NotificationsIcon />
                </CommonStyled.NotificationWrapper>
                <Styled.MenuIcon onClick={() => setOpenDrawer(true)}><MenuIcon />
                </Styled.MenuIcon>
            </Styled.MenuItemsWrapper>
            <CommonStyled.CustomDrawer
                open={openDrawer}
                onClose={() => setOpenDrawer(false)}
            >
                <CommonStyled.MenuWrapper>
                    <CommonStyled.HeaderMenu>
                        <Link to="/insight">Insight</Link>
                        <Link to="/integration">Integration</Link>
                        <Link to="/intelligence">Intelligence</Link>
                        <Link to="/ignition">Ignition</Link>
                        <Link to='#' style={{ marginTop: "22px" }} onClick={logout}>Logout</Link>
                    </CommonStyled.HeaderMenu>
                </CommonStyled.MenuWrapper>
            </CommonStyled.CustomDrawer>
        </CommonStyled.HeaderContentWrapper>
    );
};

export default HeaderComponentMobile;
