import React from 'react'
import { Autocomplete, Grid, InputAdornment, InputLabel, TextField, Typography } from '@mui/material';
import AddchartTwoToneIcon from '@mui/icons-material/AddchartTwoTone';
import Button from '@mui/material/Button';
import MenuItem from '@mui/material/MenuItem';
import { DragDropContext, Droppable, Draggable } from "react-beautiful-dnd";
import { Box } from '@mui/material'
import CloseIcon from '@mui/icons-material/Close';
import IconButton from '@mui/material/IconButton';
import Popover from '@mui/material/Popover';
import Styled from '../CommonStyle';
import StyledIn from './style'

const InsightComponent = (props) => {
    const {
        options,
        onDragEnd,
        deleteChart,
        handleClick,
        handleClose,
        openChart,
        widgetSaved,
        workflowName,
        chart,
        anchorEl,
        setInputValue,
        inputValue,
        draggableIdSelect,
        TableData,
        keplerChart,
        bubbleChart,
        geoChart,
        timeLineChart,
        scatterChart,
        pieChart,
        barChart,
        lineChart,
        updateWidgetDetail,
        widgetList,
        widgetDetail,
        handleWorkDetailChange,
        addWidget,
        widget,
        deleteWidget
    } = props;

    return (
        <>
            <Grid container>
                <Grid item lg={4} md={4} sm={12} xs={12}>
                    <StyledIn.InsightWrapper>
                        <Grid container>
                            <Grid item lg={4} md={4} sm={12} xs={12} >
                                <Box style={{ margin: "0vh 0 0 2vw" }}>
                                    <Box style={{ marginBottom: "8vh" }}>
                                        <Typography variant="h4">
                                            Get Insights
                                        </Typography>
                                    </Box>
                                    <Styled.InputWrapper>
                                        <Autocomplete
                                            style={{ width: "30vw", minWidth: "204px" }}
                                            freeSolo
                                            autoHighlight
                                            inputValue={inputValue}
                                            onInputChange={(event, newInputValue) => {
                                                setInputValue(newInputValue);
                                            }}
                                            options={inputValue && (inputValue !== widgetDetail?.name) ? widgetList?.map((option) => option.name) : []}
                                            renderInput={(params) => (
                                                <TextField {...params}
                                                    variant="outlined"
                                                    placeholder='Please type something to get different Insights...'
                                                />
                                            )}
                                        />
                                    </Styled.InputWrapper>
                                    <Styled.tryButtonWrapper>
                                        Run
                                    </Styled.tryButtonWrapper>
                                </Box>
                            </Grid>
                        </Grid>
                    </StyledIn.InsightWrapper>
                </Grid>
                <Grid item lg={4} md={4} sm={12} xs={12} >
                    <Box style={{ marginTop: "16vh" }}>
                        <Styled.InputWrapper>
                            <InputLabel>Model Output Name</InputLabel>
                            <TextField
                                select
                                name="name"
                                value={widgetDetail?.name || ""}
                                onChange={handleWorkDetailChange}
                                style={{ width: "60vh" }}
                                InputProps={{
                                    endAdornment: (
                                        <InputAdornment position="end">
                                            <IconButton
                                                aria-label="widgets"
                                                onClick={addWidget}
                                            >
                                                <AddchartTwoToneIcon />
                                            </IconButton>
                                        </InputAdornment>
                                    )
                                }}
                            // error={jobDetailDataError?.name}
                            // helperText={jobDetailDataError?.name ? `Required.` : ''}
                            >
                                {widgetList && widgetList?.length > 0 && widgetList?.map((x, i) => {
                                    return <MenuItem
                                        key={i}
                                        value={x}
                                    >
                                        {x?.name}
                                    </MenuItem>
                                })}

                            </TextField>
                        </Styled.InputWrapper>
                    </Box>
                </Grid>
                <Grid item lg={4} md={4} sm={12} xs={12} >
                    <Box style={{ marginTop: "16vh" }}>
                        <Styled.InputWrapper>
                            <InputLabel>Workflow Name</InputLabel>
                            <TextField
                                select
                                name="name"
                                value={widgetDetail?.name || ""}
                                onChange={handleWorkDetailChange}
                                style={{ width: "60vh" }}
                            // error={jobDetailDataError?.name}
                            // helperText={jobDetailDataError?.name ? `Required.` : ''}
                            >
                                {workflowName && workflowName?.length > 0 && workflowName?.map((x, i) => {
                                    return <MenuItem
                                        key={i}
                                        value={x}
                                    >
                                        {x?.name}
                                    </MenuItem>
                                })}

                            </TextField>
                        </Styled.InputWrapper>
                    </Box>
                </Grid>

                <Grid container>
                    {widget && widgetSaved.map((item, index) => (<>
                        {
                            item.graph ? (
                                < Grid item key={index} lg={6} md={6} sm={6} xs={12} >
                                    <Box htmlFor="icon-button-file" style={{ display: "flex", flexDirection: "row-reverse" }}>
                                        <IconButton color="primary" aria-label="upload picture" component="span" onClick={() => { deleteWidget(item.id) }}>
                                            <CloseIcon />
                                        </IconButton>
                                    </Box>
                                    <Box>
                                        {item.graph}
                                    </Box>
                                </Grid>
                            ) : null
                        }
                    </>
                    ))}
                </Grid>
            </Grid>
            <Grid container  >
                <Grid item md={12}>
                    <Button variant="outlined"
                        id="basic-button"
                        startIcon={<AddchartTwoToneIcon />}
                        onClick={handleClick}
                    >
                        Add Chart
                    </Button>
                    <Popover
                        open={openChart}
                        anchorEl={anchorEl}
                        onClose={handleClose}
                        anchorOrigin={{
                            vertical: 'middle',
                            horizontal: 'left',
                        }}
                        transformOrigin={{
                            horizontal: 'left',
                        }}
                        style={{ marginTop: "100px" }}
                    >
                        <MenuItem onClick={() => { lineChart(); handleClose() }}>Line Chart</MenuItem>
                        <MenuItem onClick={() => { barChart(); handleClose() }}>Bar Chart</MenuItem>
                        <MenuItem onClick={() => { pieChart(); handleClose() }}>Pie Chart</MenuItem>
                        <MenuItem onClick={() => { scatterChart(); handleClose() }}>Scatter Chart</MenuItem>
                        <MenuItem onClick={() => { timeLineChart(); handleClose() }}>TimeLine Chart</MenuItem>
                        <MenuItem onClick={() => { geoChart(); handleClose() }}>Geo Chart</MenuItem>
                        <MenuItem onClick={() => { bubbleChart(); handleClose() }}>Bubble Chart</MenuItem>
                        <MenuItem onClick={() => { keplerChart(); handleClose() }}>Kepler Chart</MenuItem>
                        <MenuItem onClick={() => { TableData(); handleClose() }}>Table</MenuItem>
                    </Popover>
                </Grid>
                <Grid item md={6}>
                    <DragDropContext onDragEnd={onDragEnd}>
                        <Droppable droppableId="droppable">
                            {(provided, snapshot) => (
                                <Box
                                    {...provided.droppableProps}
                                    ref={provided.innerRef}
                                    style={{ backgroundColor: "white" }}
                                >
                                    {chart.map((chart, index) => (
                                        <Grid container >
                                            <Draggable key={index} draggableId={index.toString()} index={index} >
                                                {(provided, snapshot) => (
                                                    < Grid item md={12} key={index} ref={provided.innerRef}
                                                        {...provided.draggableProps}
                                                        {...provided.dragHandleProps} >
                                                        <Box >
                                                            <Box htmlFor="icon-button-file" style={{ display: "flex", flexDirection: "row-reverse" }}>
                                                                <IconButton color="primary" aria-label="upload picture" component="span" onClick={() => { deleteChart(index) }}>
                                                                    <CloseIcon />
                                                                </IconButton>
                                                            </Box>
                                                            <Box>
                                                                {chart.newChart}
                                                            </Box>
                                                        </Box>
                                                    </Grid>
                                                )}
                                            </Draggable>
                                        </Grid>
                                    ))}
                                    {provided.placeholder}
                                </Box>
                            )}
                        </Droppable>
                    </DragDropContext>
                </Grid>
            </Grid>
        </>
    )
}

export default InsightComponent;