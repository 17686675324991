import { ActionTypes } from "@mui/base";
import {
    GET_CONFIG_LIST, GET_CONNECTOR_LIST, POST_CONFIG_DATA, POST_CONNECTOR_DATA, GET_SINGLE_CONNECTOR_DATA, GET_SINGLE_CONFIG_DATA, SNACKBAR_CONFIG, DELETE_CONNECTOR_RECORD,
    DELETE_CONFIGURATION_RECORD, UPDATE_SINGLE_CONNECTOR_RECORD, UPDATE_SINGLE_CONFIG_RECORD, REQUEST_SINGLE_CONNECTOR, GET_CONNECTOR_TYPE, GET_WORKFLOW_TYPE, GET_JOB_DETAILS, GET_SINGLE_JOB_DETAIL, UPDATE_SINGLE_JOB_DETAIL_RECORD, DELETE_JOB_DETAIL_RECORD, SNACKBAR_WORKFLOW, POST_WORKFLOW_DATA, REMOVE_WORKFLOW_NAME, WORKFLOW_NOT_EXIST, UPDATED_WORKFLOW_NAME_RECORD, REMOVE_UPDATED_WORKFLOW_NAME, SELECT_CONNECTOR_TAB_STATUS, CREATE_NEW_ONE, CHOOSE_EXISTING_ONE, SELECT_JOB_TAB_STATUS
} from "../Constant";

const initialState = {
    connectorList: [],
    configurationList: [],
    jobList: [],
    workFlowList: [],
    snackbar: {
        open: false,
        severity: '',
        message: '',
    },
    workFlowId: {},
    workFlowUpdated: null,
    connectorTabStatus: CREATE_NEW_ONE,
    jobTabStatus: CREATE_NEW_ONE,
};

const integrationReducer = (state = initialState, action) => {
    switch (action.type) {

        case SNACKBAR_CONFIG:
            return {
                ...state,
                snackbar: action.payload,
            }

        case SNACKBAR_WORKFLOW:
            return {
                ...state,
                snackbar: action.payload,
            }

        case WORKFLOW_NOT_EXIST:
            return {
                ...state,
                snackbar: action.payload
            }

        case UPDATED_WORKFLOW_NAME_RECORD:
            return {
                ...state,
                workFlowUpdated: action.payload.message.name
            }
        case SELECT_CONNECTOR_TAB_STATUS:
            return {
                ...state,
                connectorTabStatus: action.payload,
                singleConnector: null
            }
        case SELECT_JOB_TAB_STATUS:
            return {
                ...state,
                jobTabStatus: action.payload,
                singleJobDetail: null
            }
        case REMOVE_UPDATED_WORKFLOW_NAME:
            return {
                ...state,
                workFlowUpdated: action.payload,
                workFlowId: null
            }
        case GET_CONNECTOR_LIST:
            return {
                ...state,
                connectorList: action.payload,
            }
        case GET_CONNECTOR_TYPE:
            return {
                ...state,
                connectorType: action.payload
            }
        case GET_WORKFLOW_TYPE:
            return {
                ...state,
                workFlowList: action.payload
            }
        case GET_CONFIG_LIST:
            return {
                ...state,
                configurationList: action.payload,
            }

        case GET_JOB_DETAILS:
            return {
                ...state,
                jobList: action.payload,
            }

        case POST_CONNECTOR_DATA:
            return {
                ...state,
                connectorList: { message: [...state.connectorList.message, action.payload] },
            }

        case POST_CONFIG_DATA:
            return {
                ...state,
                jobList: { message: [...state.jobList.message, action.payload.message] },
            }

        case POST_WORKFLOW_DATA:
            return {
                ...state,
                workFlowId: action.payload.message
            }
        case REMOVE_WORKFLOW_NAME:
            return {
                ...state,
                workFlowId: action.payload
            }
        case REQUEST_SINGLE_CONNECTOR:
            return {
                ...state,
                singleConnector: action.payload,
            }

        case GET_SINGLE_CONFIG_DATA:
            return {
                ...state,
                singleConfig: action.payload,
            }

        case GET_SINGLE_JOB_DETAIL:
            return {
                ...state,
                singleJobDetail: action.payload,
            }

        case DELETE_CONNECTOR_RECORD:
            const index = state.connectorList.message.findIndex(function (o) {
                return o.connectorDetailId === action.payload;
            })
            return {
                ...state,
                connectorList: {
                    message: [...state.connectorList.message.slice(0, index),
                    ...state.connectorList.message.slice(index + 1)]
                }
            }

        case DELETE_JOB_DETAIL_RECORD:
            const index1 = state.jobList.message.findIndex(function (o) {
                return o.jobId === action.payload;
            })
            return {
                ...state,
                jobList: {
                    message: [
                        ...state.jobList.message.slice(0, index1),
                        ...state.jobList.message.slice(index1 + 1),
                    ]
                },
            }

        case UPDATE_SINGLE_CONNECTOR_RECORD:
            const index2 = state.connectorList.message.findIndex((o) => (o?.connectorDetailId === action?.payload.message.connectorDetailId));
            const newArray = [...state.connectorList.message];
            newArray[index2] = action.payload.message
            return {
                ...state,
                connectorList: { message: newArray },
            }

        case UPDATE_SINGLE_JOB_DETAIL_RECORD:
            const index3 = state.jobList.message.findIndex(function (o) {
                return o.jobId === action?.payload?.message?.jobId;
            });
            const newArray1 = [...state.jobList.message];
            newArray1[index3] = action.payload.message;
            return {
                ...state,
                jobList: { message: newArray1 },
            }

        default:
            return state;
    }
}

export default integrationReducer;