import React, { Component } from 'react';

class AwsIcon extends Component {
    render() {
        return (
            <div className={'data-ex-icons-aws'} style={{ padding: '5px 15px' }}>
                <img
                    width={'100%'}
                    src="https://d1.awsstatic.com/logos/aws-logo-lockups/poweredbyaws/PB_AWS_logo_RGB_stacked.547f032d90171cdea4dd90c258f47373c5573db5.png"
                    alt="Powered by AWS Cloud Computing"
                />
            </div>
        );
    }
}

export default AwsIcon;