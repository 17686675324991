import { axiosV2 } from "../axiosConfig";
import { GET_WIDGET_DETAILS, GET_WORKFLOW_NAME, SNACKBAR_CONFIG } from "../Constant";
import { put, call } from "redux-saga/effects";

export function* getWidgetDetailList() {
    try {
        const { data } = yield call(axiosV2.get, '/widgeDetail');
        return yield put({ type: GET_WIDGET_DETAILS, payload: data })
    }
    catch (error) {
        yield put({ type: SNACKBAR_CONFIG, payload: { open: true, severity: 'error', message: error.message } });
    }
}
export function* getWorkflowName() {
    try {
        const { data } = yield call(axiosV2.get, '/workflow');
        return yield put({ type: GET_WORKFLOW_NAME, payload: data })
    }
    catch (error) {
        yield put({ type: SNACKBAR_CONFIG, payload: { open: true, severity: 'error', message: error.message } });
    }
}