import { styled } from '@mui/system';
import { Box, Button, ButtonGroup, InputBase, Paper } from '@mui/material';

const ContentWrapper = styled(Box)({
    padding: '15px',
    borderRadius: 0,
    background: '#e5e5e5',
    width: 'Calc(100% - 300px)',
    "@media (max-width: 991px)": {
        width: '100%',
        overflow: 'hidden'
    }
});

const ButtonWrapper = styled(Box)({
    padding: '18px',
    borderRadius: 5,
    background: '#fff',
    color: '#000',
    textAlign: 'center',
    justifyContent: 'center',
    "@media (max-width: 991px)": {
        textAlign: 'inherit',
        height: 'max-content',
        overflowY: 'inherit',
    }
});

const ConfigurationWrapper = styled(Box)({
    padding: '20px',
    borderRadius: 0,
    background: '#fff',
    color: '#000',
    marginBottom: '10px',
    textAlign: 'center',
    height: 'calc(100vh - 71px)',
    overflowY: 'scroll',
    "@media (max-width: 991px)": {
        height: 'max-content',
        overflowY: 'inherit',
    }
});

const ButtonBox = styled(Box)({
    padding: '0',
    textAlign: 'right',
    padding: '15px',
    button: {
        background: '#000a3f',
        color: '#fff',
        borderRadius: '0',
        marginLeft: '20px',
        padding: '12px 30px',
        width: '145px',
        "&:hover": {
            background: '#03b4c6',
            color: '#fff',
        },
        "@media (max-width: 991px)": {
            marginLeft: '0px',
        }
    },
    "@media (max-width: 991px)": {
        display: 'flex',
        flexWrap: 'wrap',
        gap: '10px',
        justifyContent: 'flex-end',
    }
});

const ButtonGroupWrapper = styled(ButtonGroup)({
    backgroundColor: '#666666',
    border: 'none',
    padding: '0',
    borderRadius: '5px',
    button: {
        color: '#fff',
        border: 'none !important',
        background: '#205878',
        "@media (max-width: 575px)": {
            fontSize: '12px',
        }
    },
    "&:hover": {
        background: '#03b4c6 !important',
        color: '#fff',
    },
    '.active': {
        backgroundColor: '#040b24',
    }
});

const PaperWrapper = styled(Paper)({
    marginBottom: '30px',
    boxShadow: 'none',
    border: '1px solid #ccc',
    padding: '15px 0',
});

const MenuIcon = styled(Button)({
    color: '#205878',
    justifyContent: 'flex-start',
});

const Search = styled(Box)({
    position: 'relative',
    borderRadius: '5px',
    backgroundColor: 'white',
    borderBottom: '1px solid #205878',
    borderRadius: '0',
    '&:hover': {
        backgroundColor: '#fff',
    },
    marginLeft: 0,
    width: '100%',
});

const SearchIconWrapper = styled(Box)({
    height: '100%',
    position: 'absolute',
    pointerEvents: 'none',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    top: '0',
    right: '15px',
});

const StyledInputBase = styled(InputBase)({
    color: 'inherit',
    '& .MuiInputBase-input': {
        width: '100%',
        padding: '7px 15px',
    },
});

export default {
    ContentWrapper,
    ButtonWrapper,
    ConfigurationWrapper,
    ButtonBox,
    ButtonGroupWrapper,
    PaperWrapper,
    MenuIcon,
    Search,
    SearchIconWrapper,
    StyledInputBase,
};
