import { React, useEffect, useState } from 'react';
import { LayoutContainer } from '..';
import { IgnitionComponent } from '../../Components/index';
import Styled from "./style"
import { useMediaQuery, Box, Button } from '@mui/material';
import { useDispatch, useSelector } from 'react-redux';
import { Search as SearchIcon } from '@mui/icons-material';
import { getJobDetails, statusLoading } from '../../Actions/integrationAction';
import { updateJobStatusAbort, updateJobStatusRun } from '../../Actions/integrationAction';
import { LoadingButton } from '@mui/lab';
import RefreshIcon from '@mui/icons-material/Refresh';
import Skeleton from "@mui/material/Skeleton";

const IgnitionContainer = () => {
    const dispatch = useDispatch();
    const [openDrawer, setOpenDrawer] = useState(false)
    const matches = useMediaQuery('(max-width:991px)');
    const [searchFilter, setSearchFilter] = useState(null)
    const [skeletonLoader, setSkeletonLoader] = useState(true)
    useEffect(() => {
        dispatch(getJobDetails());
    }, []);

    const { jobList, jobStatusLoader } = useSelector((state) => state.ignition)

    const handleSidePanelDrawer = (flag) => {
        setOpenDrawer(flag);
    }
    const refreshJobDetail = () => {
        setSkeletonLoader(false)
        setTimeout(() => {
            setSkeletonLoader(true)
        }, 1100);
        dispatch(getJobDetails());
    }
    const columns =
        [
            { name: "jobId", label: "Job Id" },
            { name: "jobName", label: "Job" },
            { name: "jobType", label: "Type" },
            { name: "jobStatus", label: "Status" },
            { name: "chainSeqNo", label: "Chain Sequence Number" },
            { name: "workflowId", label: "Workflow Id" },
            { name: "WorkflowName", label: "Workflow Name" },
            {
                name: "jobAction", label: "Action",
                options:
                {
                    customBodyRender: (value, tableMeta) => {
                        return (checkStatus(tableMeta.rowData[3], tableMeta.rowData[0]))
                    }
                }
            },
        ];

    const data = jobList?.message?.map((singleJob) => ({
        chainSeqNo: singleJob?.chainSeqNo,
        jobId: singleJob?.jobId,
        jobName: singleJob.name ? singleJob.name : "JOB NAME",
        jobType: singleJob?.type,
        jobStatus: singleJob?.status,
        workflowId: singleJob?.workflow?.workflowId,
        WorkflowName: singleJob?.workflow?.name,
        isLoading: jobStatusLoader
    }));

    const checkStatus = (jobStatus, jobId) => {
        switch (jobStatus) {
            case "RUN":
                return (
                    <>
                        <LoadingButton loading={(jobStatusLoader.id === jobId) && jobStatusLoader.status} variant="contained" size="small" sx={{ margin: "0px 10px", width: "75px" }} onClick={() => { dispatch(updateJobStatusRun(jobId)); dispatch(statusLoading(jobId)) }}>RUN</LoadingButton>
                    </>
                );
            case "RUNNING":
                return <LoadingButton loading={(jobStatusLoader.id === jobId) && jobStatusLoader.status} variant="contained" size="small" sx={{ margin: "0px 10px", width: "75px" }} onClick={() => { dispatch(updateJobStatusAbort(jobId)); dispatch(statusLoading(jobId)) }}>ABORT</LoadingButton >

            case "CANCEL":
                return <LoadingButton loading={(jobStatusLoader.id === jobId) && jobStatusLoader.status} variant="contained" size="small" sx={{ margin: "0px 10px", width: "75px" }} onClick={() => { dispatch(updateJobStatusRun(jobId)); dispatch(statusLoading(jobId)) }}>RE-RUN</LoadingButton>
            case "STOP":
                return <LoadingButton loading={(jobStatusLoader.id === jobId) && jobStatusLoader.status} variant="contained" size="small" sx={{ margin: "0px 10px", width: "75px" }} onClick={() => { dispatch(updateJobStatusRun(jobId)); dispatch(statusLoading(jobId)) }}>RUN</LoadingButton>
            default:
                return null;
        }
    }

    const options = {
        filter: true,
        responsive: true,
        download: false,
        sort: true,
        selectableRows: false,
        print: false,
        viewColumns: false,
        searchOpen: false,
        searchable: true,
        search: true,
        searchText: searchFilter,
        searchPlaceholder: 'Type to search.....',
    };

    return (
        <LayoutContainer>
            <Styled.ContentWrapper>
                {matches && (
                    <Styled.ButtonWrapper>
                        <Styled.Search>
                            <Styled.StyledInputBase fullWidth placeholder="Search…" inputProps={{ 'aria-label': 'search' }} />
                            <Styled.SearchIconWrapper>
                                <SearchIcon />
                            </Styled.SearchIconWrapper>
                        </Styled.Search>
                    </Styled.ButtonWrapper>
                )}
                <Styled.JobWrapper>
                    <Box style={{ display: "flex", marginBottom: "20px", width: "100%", justifyContent: "flex-end" }}   >
                        <Button onClick={refreshJobDetail}>
                            <RefreshIcon />
                        </Button>
                    </Box>
                    <Styled.PaperWrapper>
                        {
                            (jobList?.message?.length > 1) && skeletonLoader ? (<>
                                <IgnitionComponent
                                    columns={columns}
                                    data={data}
                                    options={options}
                                />
                            </>) : (<>
                                <Box sx={{ display: "flex", flexDirection: "column", margin: "0 2vw 0 2vw" }}>
                                    <Skeleton height="10vh" animation="wave" />
                                    <Skeleton height="10vh" animation="wave" />
                                    <Skeleton height="10vh" animation="wave" />
                                    <Skeleton height="10vh" animation="wave" />
                                    <Skeleton height="10vh" animation="wave" />
                                    <Skeleton height="10vh" animation="wave" />
                                    <Skeleton height="10vh" animation="wave" />
                                    <Skeleton height="10vh" animation="wave" />
                                    <Skeleton height="10vh" animation="wave" />
                                </Box>
                            </>)}
                    </Styled.PaperWrapper>
                </Styled.JobWrapper>
            </Styled.ContentWrapper>
        </LayoutContainer>
    );
};

export default IgnitionContainer;
