import {
  BrowserRouter as Router,
  Route,
  Routes
} from "react-router-dom";
import { Provider } from 'react-redux';
import {
  IntegrationContainer,
  IgnitionContainer,
  InsightContainer,
  IntelligenceContainer,
} from "./Containers";
import AwsLogin, { AWS_LOGIN_URL } from './Components/cloud-provider/AWS/aws-login';
import { withAuthenticator } from "@aws-amplify/ui-react";
import { store, persistor } from './store';
import { PersistGate } from 'redux-persist/integration/react'

function App() {
  return (
    <Provider store={store}>
      <PersistGate loading={null} persistor={persistor}>
        <Router>
          <Routes>
            <Route exact path={AWS_LOGIN_URL} element={<AwsLogin />} />
            <Route exact path="/" element={<InsightContainer />} />
            <Route exact path="/integration" element={<IntegrationContainer />} />
            <Route exact path="/intelligence" element={<IntelligenceContainer />} />
            <Route exact path="/ignition" element={<IgnitionContainer />} />
            <Route exact path="/insight" element={<InsightContainer />} />
          </Routes >
        </Router >
      </PersistGate>
    </Provider >
  );
}

export default withAuthenticator(App);
