import React, { useEffect, useState } from 'react'
import { Chart } from "react-google-charts";

const ScatterChart = () => {
    const [data, setData] = useState({ value: [] });
    const [option, setOption] = useState({
        title: null,
        curveType: null
    })

    useEffect(() => {
        fetch('/chart_data.json')
            .then(response => response.json())
            .then((jsonData) => {
                const chartData = jsonData.scatter
                let values = []
                chartData.data.map((data) => {
                    values.push(data)
                })
                setData({ ...data, value: values });
                setOption({ ...option, title: chartData.title, curveType: chartData.curveType })
            })
            .catch((error) => {
                console.error("Unabled to fetch json")
            })
    }, []);

    return (
        <>
            <Chart
                chartType="ScatterChart"
                data={data.value}
                options={option}
            />
        </>
    )
}

export default ScatterChart