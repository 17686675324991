import AwsProvider from './AWS/aws-provider';

const { AWS_ACCOUNT_NAME } = "pranav.joshi@galileosense.com";

export const DEFAULT_CLOUD_PROVIDER = 'aws';

export const CLOUD_PROVIDERS = [
    new AwsProvider(AWS_ACCOUNT_NAME)
];

