import React, { useEffect, useState } from 'react'
import { Chart } from "react-google-charts";

const BarChart = () => {

    const [data, setData] = useState({
        value: [],
        title: null,
        subtitle: null,
    });

    useEffect(() => {
        fetch('/chart_data.json')
            .then(response => response.json())
            .then((jsonData) => {
                const chartData = jsonData.bar
                let values = []
                chartData.data.map((data) => {
                    values.push(data)
                })
                setData({ ...data, value: values, title: chartData.title, subtitle: chartData.subtitle });
            })
            .catch((error) => {
                console.error("Unabled to fetch json")
            })
    }, []);
    const options = {
        chart: {
            title: data.title,
            subtitle: data.subtitle,
        }
    }


    return (
        <>
            <Chart
                chartType="Bar"
                data={data.value}
                options={options}
            />
        </>
    )
}

export default BarChart