import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Alert as MuiAlert, Snackbar, useMediaQuery } from '@mui/material';
import { MainContentWrapper } from "./style";
import {
    HeaderComponent,
    FooterComponent,
    HeaderComponentMobile,
} from '../../Components';
import { snackBarCloseConfig } from '../../Actions/integrationAction';

const LayoutContainer = (props) => {
    const { children } = props;
    const dispatch = useDispatch();
    const { snackbar } = useSelector((state) => state.integrationReducer);
    const handleClose = () => {
        dispatch(snackBarCloseConfig());
    };

    const matches = useMediaQuery('(max-width:991px)');

    const Alert = React.forwardRef(function Alert(props, ref) {
        return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
    });

    return (
        <>
            <Snackbar open={snackbar.open} autoHideDuration={4000} onClose={handleClose}>
                <Alert onClose={handleClose} severity={snackbar.severity} sx={{ width: '100%' }}>
                    {snackbar.message}
                </Alert>
            </Snackbar>
            {matches ? <HeaderComponentMobile /> : <HeaderComponent />}
            <MainContentWrapper>
                {children}
            </MainContentWrapper>
            {/* <FooterComponent /> */}
        </>
    );
};

export default LayoutContainer;
