import { Button, Grid, IconButton, InputLabel, MenuItem, Select, Switch, TextField, Typography } from '@mui/material';
import React, { useState } from 'react';
import { CHOOSE_EXISTING_ONE, CONNECTOR_TYPE_LIST, CREATE_NEW_ONE, GOOGLE_STATIC_API } from '../../Constant';
import InputAdornment from "@material-ui/core/InputAdornment";
import Visibility from "@mui/icons-material/Visibility";
import VisibilityOff from "@mui/icons-material/VisibilityOff";
import Styled from '../CommonStyle';
import { ViewSidebar as ViewSidebarIcon, Search as SearchIcon } from '@mui/icons-material';
import StyledIN from '../../Containers/IntegrationContainer/style';
const label = { inputProps: { 'aria-label': 'isSourceRequestPayer' } };

const ConnectorComponent = (props) => {
    const {
        connectorTabOption,
        handleConnectorChange,
        connectorError,
        connectorData,
        handleSidePanelDrawer,
        connectorList,
        getSingleConnector,
        connectorType,
        showText,
        matches,
        handleChooseExisting,
        handleCreateNew,
        handleClickShowTextSecretKey,
        handleMouseDownPasswordSecretKey,
        handleClickShowTextAccessKey,
        handleMouseDownPasswordAccessKey,
        handleClickShowTextApiKey,
        handleMouseDownPasswordApikey,
    } = props;

    return (
        <>
            <StyledIN.ButtonWrapper>
                <Grid container>
                    <Grid style={{ display: 'flex', alignItems: 'center', }} item xs={3} sm={3} md={4}>
                        {matches && <StyledIN.MenuIcon onClick={() => handleSidePanelDrawer(true)}><ViewSidebarIcon fontSize='large' /></StyledIN.MenuIcon>}
                    </Grid>
                    <Grid style={{ display: 'flex', alignItems: 'center', justifyContent: !matches ? 'center' : 'flex-start', }} item xs={matches ? 9 : 12} sm={matches ? 9 : 12} md={matches ? 8 : 12}>
                        <StyledIN.ButtonGroupWrapper>
                            <Button className={connectorTabOption === CREATE_NEW_ONE ? 'active' : ''} onClick={handleCreateNew}>Create New One</Button>
                            <Button className={connectorTabOption === CHOOSE_EXISTING_ONE ? 'active' : ''} onClick={handleChooseExisting}>Choose Existing One</Button>
                        </StyledIN.ButtonGroupWrapper>
                    </Grid>
                </Grid>
            </StyledIN.ButtonWrapper>
            <Grid container style={{ padding: '15px' }} spacing={2}>
                <Grid item xs={12}>
                    <Styled.InputWrapper>
                        <Typography>Connector Form</Typography>
                    </Styled.InputWrapper>
                </Grid>
                {connectorTabOption === CHOOSE_EXISTING_ONE && (
                    <>
                        < Grid item lg={4} md={4} sm={6} xs={12} >
                            <Styled.InputWrapper>
                                <InputLabel>Connector Name</InputLabel>
                                <Select
                                    fullWidth
                                    name="connectorId"
                                    value={connectorData?.connectorName}
                                    onChange={handleConnectorChange}
                                >
                                    {connectorList && Object.keys(connectorList).length > 0 && connectorList.message.map((x, i) => {
                                        return <MenuItem
                                            key={i}
                                            value={x.name}
                                            onClick={() => getSingleConnector(x)}
                                        >
                                            {x.name}
                                        </MenuItem>
                                    })}
                                </Select>
                            </Styled.InputWrapper>
                        </Grid>
                    </>
                )}
                {connectorTabOption === CREATE_NEW_ONE && (
                    <Grid item lg={4} md={4} sm={6} xs={12} >
                        <Styled.InputWrapper>
                            <InputLabel>Connector Type</InputLabel>
                            <Select
                                fullWidth
                                name="connectorType"
                                value={connectorData?.connectorType}
                                onChange={handleConnectorChange}>
                                {connectorType?.message.map((x, i) => <MenuItem key={i} value={x?.name}>{x?.name}</MenuItem>)}
                            </Select>
                        </Styled.InputWrapper>
                    </Grid>
                )}
                {connectorData.connectorType === "AWS-S3" && (
                    <>
                        {connectorTabOption === CHOOSE_EXISTING_ONE && (<Grid item lg={4} md={4} sm={6} xs={12} >
                            <Styled.InputWrapper>
                                <InputLabel>Connector Type</InputLabel>
                                <TextField
                                    fullWidth
                                    name="connectorName"
                                    value={connectorData?.connectorType}
                                    onChange={handleConnectorChange}
                                    error={connectorError?.connectorType}
                                    disabled
                                    helperText={connectorError?.connectorType ? `Required.` : ''}
                                />
                            </Styled.InputWrapper>
                        </Grid>)}
                        {connectorTabOption === CREATE_NEW_ONE && (<Grid item lg={4} md={4} sm={6} xs={12} >
                            <Styled.InputWrapper>
                                <InputLabel>Connector Name</InputLabel>
                                <TextField
                                    fullWidth
                                    name="connectorName"
                                    value={connectorData?.connectorName}
                                    onChange={handleConnectorChange}
                                    error={connectorError?.connectorName}
                                    helperText={connectorError?.connectorName ? `Required.` : ''}
                                />
                            </Styled.InputWrapper>
                        </Grid>)}

                        <Grid item lg={4} md={4} sm={6} xs={12}>
                            <Styled.InputWrapper>
                                <InputLabel>Description</InputLabel>
                                <TextField
                                    fullWidth
                                    name="description"
                                    value={connectorData?.description}
                                    onChange={handleConnectorChange}
                                    error={connectorError?.description}
                                    helperText={connectorError?.description ? `Required.` : ''}
                                />
                            </Styled.InputWrapper>
                        </Grid>
                        <Grid item xs={12} >
                            <Styled.InputWrapper>
                                <Styled.SwitchWrapper>
                                    <InputLabel>is Source Request Payer?</InputLabel>
                                    <Switch
                                        name="isSourceRequestPayer"
                                        value={connectorData?.isSourceRequestPayer}
                                        checked={connectorData.isSourceRequestPayer ? true : false}
                                        onChange={handleConnectorChange}
                                        {...label} />
                                </Styled.SwitchWrapper>
                            </Styled.InputWrapper>
                        </Grid>
                        <Grid item lg={4} md={4} sm={6} xs={12} >
                            <Styled.InputWrapper>
                                <InputLabel>Access Key</InputLabel>
                                <TextField
                                    fullWidth
                                    type={showText.accessKey ? 'text' : 'password'}
                                    name="accessKey"
                                    value={connectorData?.accessKey}
                                    onChange={handleConnectorChange}
                                    error={connectorError?.accessKey}
                                    InputProps={{
                                        endAdornment: (
                                            <InputAdornment position="end">
                                                <IconButton
                                                    aria-label="toggle password visibility"
                                                    onClick={handleClickShowTextAccessKey}
                                                    onMouseDown={handleMouseDownPasswordAccessKey}
                                                >
                                                    {showText.accessKey ? <Visibility /> : <VisibilityOff />}
                                                </IconButton>
                                            </InputAdornment>
                                        )
                                    }}
                                    helperText={connectorError?.accessKey ? `Required.` : ''}
                                />
                            </Styled.InputWrapper>
                        </Grid>
                        <Grid item lg={4} md={4} sm={6} xs={12} >
                            <Styled.InputWrapper>
                                <InputLabel>Secret Key</InputLabel>
                                <TextField
                                    fullWidth
                                    type={showText.secretKey ? 'text' : 'password'}
                                    name="secretKey"
                                    value={connectorData?.secretKey}
                                    onChange={handleConnectorChange}
                                    error={connectorError?.secretKey}
                                    InputProps={{
                                        endAdornment: (
                                            <InputAdornment position="end">
                                                <IconButton
                                                    aria-label="toggle password visibility"
                                                    onClick={handleClickShowTextSecretKey}
                                                    onMouseDown={handleMouseDownPasswordSecretKey}
                                                >
                                                    {showText.secretKey ? <Visibility /> : <VisibilityOff />}
                                                </IconButton>
                                            </InputAdornment>
                                        )
                                    }}
                                    helperText={connectorError?.secretKey ? `Required.` : ''}
                                />
                            </Styled.InputWrapper>
                        </Grid>
                        <Grid item lg={4} md={4} sm={6} xs={12} >
                            <Styled.InputWrapper>
                                <InputLabel>Region</InputLabel>
                                <TextField
                                    fullWidth
                                    name="region"
                                    value={connectorData?.region}
                                    onChange={handleConnectorChange}
                                    error={connectorError?.region}
                                    helperText={connectorError?.region ? `Required.` : ''}
                                />
                            </Styled.InputWrapper>
                        </Grid>
                    </>
                )}
                {connectorData.connectorType === "Google Static API" && (
                    <>
                        {connectorTabOption === CHOOSE_EXISTING_ONE && (<Grid item lg={4} md={4} sm={6} xs={12} >
                            <Styled.InputWrapper>
                                <InputLabel>Connector Type</InputLabel>
                                <TextField
                                    fullWidth
                                    name="connectorName"
                                    disabled
                                    value={connectorData?.connectorType}
                                    onChange={handleConnectorChange}
                                    error={connectorError?.connectorType}
                                    helperText={connectorError?.connectorType ? `Required.` : ''}
                                />
                            </Styled.InputWrapper>
                        </Grid>)}
                        {connectorTabOption === CREATE_NEW_ONE && (
                            <Grid item lg={4} md={4} sm={6} xs={12} >
                                <Styled.InputWrapper>
                                    <InputLabel>Connector Name</InputLabel>
                                    <TextField
                                        fullWidth
                                        name="connectorName"
                                        value={connectorData?.connectorName}
                                        onChange={handleConnectorChange}
                                        error={connectorError?.connectorName}
                                        helperText={connectorError?.connectorName ? `Required.` : ''}
                                    />
                                </Styled.InputWrapper>
                            </Grid>)}

                        <Grid item lg={4} md={4} sm={6} xs={12}>
                            <Styled.InputWrapper>
                                <InputLabel>Description</InputLabel>
                                <TextField
                                    fullWidth
                                    name="description"
                                    value={connectorData?.description}
                                    onChange={handleConnectorChange}
                                    error={connectorError?.description}
                                    helperText={connectorError?.description ? `Required.` : ''}
                                />
                            </Styled.InputWrapper>
                        </Grid>
                        <Grid item lg={4} md={4} sm={6} xs={12} >
                            <Styled.InputWrapper>
                                <InputLabel>Secret Key</InputLabel>
                                <TextField
                                    fullWidth
                                    type={showText.secretKey ? 'text' : 'password'}
                                    name="secret"
                                    value={connectorData?.secret}
                                    onChange={handleConnectorChange}
                                    error={connectorError?.secret}
                                    InputProps={{
                                        endAdornment: (
                                            <InputAdornment position="end">
                                                <IconButton
                                                    aria-label="toggle password visibility"
                                                    onClick={handleClickShowTextSecretKey}
                                                    onMouseDown={handleMouseDownPasswordSecretKey}
                                                >
                                                    {showText.secretKey ? <Visibility /> : <VisibilityOff />}
                                                </IconButton>
                                            </InputAdornment>
                                        )
                                    }}
                                    helperText={connectorError?.secret ? `Required.` : ''}
                                />
                            </Styled.InputWrapper>
                        </Grid>
                        <Grid item lg={4} md={4} sm={6} xs={12} >
                            <Styled.InputWrapper>
                                <InputLabel>API Key</InputLabel>
                                <TextField
                                    fullWidth
                                    type={showText.apiKey ? 'text' : 'password'}
                                    name="apiKey"
                                    value={connectorData?.apiKey}
                                    onChange={handleConnectorChange}
                                    error={connectorError?.apiKey}
                                    InputProps={{
                                        endAdornment: (
                                            <InputAdornment position="end">
                                                <IconButton
                                                    aria-label="toggle password visibility"
                                                    onClick={handleClickShowTextApiKey}
                                                    onMouseDown={handleMouseDownPasswordApikey}
                                                >
                                                    {showText.apiKey ? <Visibility /> : <VisibilityOff />}
                                                </IconButton>
                                            </InputAdornment>
                                        )
                                    }}
                                    helperText={connectorError?.apiKey ? `Required.` : ''}
                                />
                            </Styled.InputWrapper>
                        </Grid>
                    </>
                )}
                {connectorData.connectorType === "Maxar" && (
                    <>
                        {connectorTabOption === CHOOSE_EXISTING_ONE && (
                            <Grid item lg={4} md={4} sm={6} xs={12} >
                                <Styled.InputWrapper>
                                    <InputLabel>Connector Type</InputLabel>
                                    <TextField
                                        fullWidth
                                        name="connectorName"
                                        disabled
                                        value={connectorData?.connectorType}
                                        onChange={handleConnectorChange}
                                        error={connectorError?.connectorType}
                                        helperText={connectorError?.connectorType ? `Required.` : ''}
                                    />
                                </Styled.InputWrapper>
                            </Grid>)}
                        {connectorTabOption === CREATE_NEW_ONE && (
                            <Grid item lg={4} md={4} sm={6} xs={12} >
                                <Styled.InputWrapper>
                                    <InputLabel>Connector Name</InputLabel>
                                    <TextField
                                        fullWidth
                                        name="connectorName"
                                        value={connectorData?.connectorName}
                                        onChange={handleConnectorChange}
                                        error={connectorError?.connectorName}
                                        helperText={connectorError?.connectorName ? `Required.` : ''}
                                    />
                                </Styled.InputWrapper>
                            </Grid>)}
                        <Grid item lg={4} md={4} sm={6} xs={12}>
                            <Styled.InputWrapper>
                                <InputLabel>Description</InputLabel>
                                <TextField
                                    fullWidth
                                    name="description"
                                    value={connectorData?.description}
                                    onChange={handleConnectorChange}
                                    error={connectorError?.description}
                                    helperText={connectorError?.description ? `Required.` : ''}
                                />
                            </Styled.InputWrapper>
                        </Grid>
                        <Grid item lg={4} md={4} sm={6} xs={12}>
                            <Styled.InputWrapper>
                                <InputLabel>Delivery Platform</InputLabel>
                                <TextField
                                    fullWidth
                                    disabled
                                    name="deliveryPlatform"
                                    value={connectorData?.deliveryPlatform}
                                    onChange={handleConnectorChange}
                                    error={connectorError?.deliveryPlatform}
                                    helperText={connectorError?.deliveryPlatform ? `Required.` : ''}
                                />
                            </Styled.InputWrapper>
                        </Grid>
                        <Grid item lg={4} md={4} sm={6} xs={12} >
                            <Styled.InputWrapper>
                                <InputLabel>Registered Credentials ID</InputLabel>
                                <TextField
                                    fullWidth
                                    type={showText.apiKey ? 'text' : 'password'}
                                    name="credentialId"
                                    value={connectorData?.credentialId}
                                    onChange={handleConnectorChange}
                                    error={connectorError?.credentialId}
                                    InputProps={{
                                        endAdornment: (
                                            <InputAdornment position="end">
                                                <IconButton
                                                    aria-label="toggle password visibility"
                                                    onClick={handleClickShowTextApiKey}
                                                    onMouseDown={handleMouseDownPasswordApikey}
                                                >
                                                    {showText.apiKey ? <Visibility /> : <VisibilityOff />}
                                                </IconButton>
                                            </InputAdornment>
                                        )
                                    }}
                                    helperText={connectorError?.credentialId ? `Required.` : ''}
                                />
                            </Styled.InputWrapper>
                        </Grid>
                    </>
                )}
                {connectorData.connectorType === "Google Place Detail API" && (
                    <>
                        {connectorTabOption === CHOOSE_EXISTING_ONE && (<Grid item lg={4} md={4} sm={6} xs={12} >
                            <Styled.InputWrapper>
                                <InputLabel>Connector Type</InputLabel>
                                <TextField
                                    fullWidth
                                    name="connectorName"
                                    disabled
                                    value={connectorData?.connectorType}
                                    onChange={handleConnectorChange}
                                    error={connectorError?.connectorType}
                                    helperText={connectorError?.connectorType ? `Required.` : ''}
                                />
                            </Styled.InputWrapper>
                        </Grid>)}
                        {connectorTabOption === CREATE_NEW_ONE && (<Grid item lg={4} md={4} sm={6} xs={12} >
                            <Styled.InputWrapper>
                                <InputLabel>Connector Name</InputLabel>
                                <TextField
                                    fullWidth
                                    name="connectorName"
                                    value={connectorData?.connectorName}
                                    onChange={handleConnectorChange}
                                    error={connectorError?.connectorName}
                                    helperText={connectorError?.connectorName ? `Required.` : ''}
                                />
                            </Styled.InputWrapper>
                        </Grid>)}
                        <Grid item lg={4} md={4} sm={6} xs={12}>
                            <Styled.InputWrapper>
                                <InputLabel>Description</InputLabel>
                                <TextField
                                    fullWidth
                                    name="description"
                                    value={connectorData?.description}
                                    onChange={handleConnectorChange}
                                    error={connectorError?.description}
                                    helperText={connectorError?.description ? `Required.` : ''}
                                />
                            </Styled.InputWrapper>
                        </Grid>
                        <Grid item lg={4} md={4} sm={6} xs={12} >
                            <Styled.InputWrapper>
                                <InputLabel>API Key</InputLabel>
                                <TextField
                                    fullWidth
                                    type={showText.apiKey ? 'text' : 'password'}
                                    name="apiKey"
                                    value={connectorData?.apiKey}
                                    onChange={handleConnectorChange}
                                    error={connectorError?.apiKey}
                                    InputProps={{
                                        endAdornment: (
                                            <InputAdornment position="end">
                                                <IconButton
                                                    aria-label="toggle password visibility"
                                                    onClick={handleClickShowTextApiKey}
                                                    onMouseDown={handleMouseDownPasswordApikey}
                                                >
                                                    {showText.apiKey ? <Visibility /> : <VisibilityOff />}
                                                </IconButton>
                                            </InputAdornment>
                                        )
                                    }}
                                    helperText={connectorError?.apiKey ? `Required.` : ''}
                                />
                            </Styled.InputWrapper>
                        </Grid>
                    </>
                )}
                {connectorData.connectorType === "Google Cloud Storage" && (
                    <>
                        {connectorTabOption === CHOOSE_EXISTING_ONE && (<Grid item lg={4} md={4} sm={6} xs={12} >
                            <Styled.InputWrapper>
                                <InputLabel>Connector Type</InputLabel>
                                <TextField
                                    fullWidth
                                    name="connectorName"
                                    disabled
                                    value={connectorData?.connectorType}
                                    onChange={handleConnectorChange}
                                    error={connectorError?.connectorType}
                                    helperText={connectorError?.connectorType ? `Required.` : ''}
                                />
                            </Styled.InputWrapper>
                        </Grid>)}
                        {connectorTabOption === CREATE_NEW_ONE && (<Grid item lg={4} md={4} sm={6} xs={12} >
                            <Styled.InputWrapper>
                                <InputLabel>Connector Name</InputLabel>
                                <TextField
                                    fullWidth
                                    name="connectorName"
                                    value={connectorData?.connectorName}
                                    onChange={handleConnectorChange}
                                    error={connectorError?.connectorName}
                                    helperText={connectorError?.connectorName ? `Required.` : ''}
                                />
                            </Styled.InputWrapper>
                        </Grid>)}
                        <Grid item lg={4} md={4} sm={6} xs={12}>
                            <Styled.InputWrapper>
                                <InputLabel>Description</InputLabel>
                                <TextField
                                    fullWidth
                                    name="description"
                                    value={connectorData?.description}
                                    onChange={handleConnectorChange}
                                    error={connectorError?.description}
                                    helperText={connectorError?.description ? `Required.` : ''}
                                />
                            </Styled.InputWrapper>
                        </Grid>
                    </>
                )}
            </Grid>
        </>

    );
};

export default ConnectorComponent;
