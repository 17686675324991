import { Box, Button, Drawer } from '@mui/material';
import { styled } from '@mui/system';

const MobileHeaderWrapper = styled(Box)({
    width: '70px',
    height: '70px',
});

const MenuIcon = styled(Button)({
    color: '#fff',
});

const MenuItemsWrapper = styled(Box)({
    "@media (max-width: 991px)": {
        display: 'flex',
    }
});

export default {
    MobileHeaderWrapper,
    MenuIcon,
    MenuItemsWrapper,
};