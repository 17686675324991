import { MenuItem, Select } from '@mui/material';
import React, { useState } from 'react';
import CommonStyled from '../CommonStyle';

const SidePanelComponentMobile = (props) => {
    const {
        handleSidePanelDrawer,
        openDrawer
    } = props;


    return (
        <CommonStyled.CustomDrawer
            open={openDrawer}
            onClose={() => handleSidePanelDrawer(false)}
        >
            <CommonStyled.Sidebar>
                <CommonStyled.DropDownWrapper />
            </CommonStyled.Sidebar>
        </CommonStyled.CustomDrawer >
    );
};

export default SidePanelComponentMobile;
