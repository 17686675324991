import {
    //SNACK
    DISPLAY_SNACKBAR,
    UPDATE_JOB_STATUS_ABORT,
    UPDATE_JOB_STATUS_RUN,
    GET_JOB_DETAILS,
    START_JOB_STATUS_LOADER,
} from "../Constant";

const initialState = {
    jobList: [],
    snackbar: {
        open: false,
        severity: '',
        message: '',
    },
    jobStatusLoader: '',
};

const igniteReducer = (state = initialState, action) => {
    switch (action.type) {
        case DISPLAY_SNACKBAR:
            return {
                ...state,
                snackbar: action.payload,
            }

        case UPDATE_JOB_STATUS_RUN:
            let temp = [...state.jobList.message]
            let findJobIndex = temp.findIndex((item) => item.jobId === action.payload.id)
            temp[findJobIndex].status = action.payload.status
            return {
                ...state,
                jobList: { message: temp },
                jobStatusLoader: false
            }

        case START_JOB_STATUS_LOADER:
            return {
                ...state,
                jobStatusLoader: { id: action.payload.jobId, status: true }
            }

        case UPDATE_JOB_STATUS_ABORT:
            let temp1 = [...state.jobList.message]
            let findJobIndex1 = temp1.findIndex((item1) => item1.jobId === action.payload.id)
            temp1[findJobIndex1].status = action.payload.status
            return {
                ...state,
                jobList: { message: temp1 },
                jobStatusLoader: false
            }

        case GET_JOB_DETAILS:
            return {
                ...state,
                jobList: action.payload,
            }

        default:
            return state;

    }
}
export default igniteReducer;