import { styled } from '@mui/system';
import { Box, ButtonGroup, Paper } from '@mui/material';


export const ContentWrapper = styled('div')({
    display: "flex",
    flexDirection: "column",
    borderRadius: 0,
    background: 'white',
    width: '100%'
});

export const PaperWrapperBar = styled('div')({
    width: "100%",
    height: "100%",
    backgroundColor: 'white'
});

export const PaperWrapperLine = styled('div')({
    width: "100%",
    height: "100%",
    backgroundColor: 'white'
});
export const PaperWrapperPieG = styled('div')({
    width: "100%",
    height: "90%",
    backgroundColor: 'white'
});
export const PaperWrapperPie = styled('div')({
    width: "335px",
    height: "385px",
    margin: "60px",
    backgroundColor: 'white'
});


export const ButtonWrapper = styled(Box)({
    padding: '20px',
    borderRadius: 5,
    background: '#fff',
    color: '#000',
    marginBottom: '10px',
    textAlign: 'center',
    justifyContent: 'center',
    "@media (max-width: 991px)": {
        textAlign: 'inherit',
        height: 'max-content',
        overflowY: 'inherit',
    }
});

export const JobWrapper = styled(Box)({
    padding: '20px',
    borderRadius: 0,
    background: '#fff',
    color: '#000',
    marginBottom: '10px',
    textAlign: 'center',
    height: 'calc(100vh - 166px)',
    overflowY: 'auto',
    "@media (max-width: 991px)": {
        height: 'max-content',
        overflowY: 'inherit',
    }
});

export const ButtonBox = styled(Box)({
    padding: '0',
    textAlign: 'right',
    button: {
        background: '#000a3f',
        color: '#fff',
        borderRadius: '0',
        marginLeft: '20px',
        padding: '12px 30px',
        width: '100px',
        "&:hover": {
            background: '#03b4c6',
            color: '#fff',
        },
        "@media (max-width: 991px)": {
            marginLeft: '0px',
        }
    },
    "@media (max-width: 991px)": {
        display: 'flex',
        flexWrap: 'wrap',
        gap: '10px',
        justifyContent: 'flex-end',
    }
});

export const ButtonGroupWrapper = styled(ButtonGroup)({
    backgroundColor: '#666666',
    border: 'none',
    padding: '0',
    borderRadius: '5px',
    button: {
        color: '#fff',
        border: 'none !important',
        background: '#205878',
        "@media (max-width: 575px)": {
            fontSize: '12px',
        }
    },
    "&:hover": {
        background: '#03b4c6 !important',
        color: '#fff',
    },
    '.active': {
        backgroundColor: '#040b24',
    }
});

export const PaperWrapper = styled(Paper)({
    marginBottom: '30px',
    boxShadow: 'none',
    border: '1px solid #ccc',
    padding: '0 0',
    "& .MuiTableBody-root": {
        width: "60%",
    },
});

export default {};